import React from "react";
import cross from "../../assets/Images/cross.png";
import "./style.css";
import CaptureEyeTab from "../CaptureEyeTab";
import TestReportBox from "../TestReportBox";

function AddPatientBox(props) {
  const { open, close } = props;
  // const [value, setValue] = React.useState(0);
  // const [emailId, setEmailId] = React.useState(true);
  // const [pId, setpId] = React.useState(false);
  const [gender, setGender] = React.useState("male");
  const [preg, setPreg] = React.useState("no");
  const [test, setTest] = React.useState(true);
  const [report, setReport] = React.useState(false);

  function openReport() {
    setTest(!test);
    setReport(!report);
  }
  function closeReport() {
    setTest(false);
    setReport(false);
  }
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // function selectEmail() {
  //   setEmailId(true);
  //   setpId(false);
  // }
  // function selectId() {
  //   setpId(true);
  //   setEmailId(false);
  // }
  function changeGender(event) {
    setGender(event.target.value);
  }
  function changePreg(event) {
    setPreg(event.target.value);
  }

  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box">
              <p className="title">Start Hb Test</p>
              <div className="p-1" onClick={close}>
                <img src={cross} alt="cross-icon" height="20" width="20" />
              </div>
            </div>
            <div className="mt-10 mb-3">
              <input required placeholder="Name" className="input w-full" />
            </div>
            <div className="heading-box">
              <div className={gender === "female" ? "w-[22rem]" : "w-full"}>
                <p className="gender-text">Select Gender</p>
                <select value={gender} onChange={changeGender} className="input w-full">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {gender === "female" && (
                <div>
                  <p className="gender-text">Are you pregneant?</p>
                  <select value={preg} onChange={changePreg} className="input w-[22rem]">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              )}
            </div>
            <div>
              <CaptureEyeTab/>
            </div>
            <div>
              <button className="detect-anemia-btn" onClick={openReport}>Detect Anemia</button>
            </div>
            <TestReportBox open={report} close={closeReport} /> 
          </div>
        </div>
      )}
    </div>
  );
}

export default AddPatientBox;

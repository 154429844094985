import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Frame from "../../assets/Images/Frame.png";
import Monere from "../../assets/Images/Monere.png";
import appstore from "../../assets/Images/appstore.png";
import playstore from "../../assets/Images/playstore.png";
import fb from "../../assets/Images/fb.png";
import insta from "../../assets/Images/insta.png";
import xlogo from "../../assets/Images/logo-x-.png";
import linkedin from "../../assets/Images/linkedin.png";
import call from "../../assets/Images/call.png";
import mail from "../../assets/Images/mail.png";
import location from "../../assets/Images/location.png";
import "../NewFooter/style.css";
import "../../style/Typography.css";

const CustomLink = styled("Button")({
  border: "none",
  boxShadow: "none",
  marginRight: 30,
  backgroundColor: "transparent",
  // backgroundColor: 'pink',
  color: "#8B979E",
  padding: 0,
  paddingTop: 5,
  paddingBottom: 5,
  // display: 'flex',
  // alignSelf: 'flex-start'
  fontFamily: "Poppins-SemiBold",
  fontSize: 16,
});

const GridBody = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const GridItem = {
  display: "flex",
  flexDirection: "column",
  paddingBottom: 2,
  justifyContent: "center",
};

const CustGrid = {
  // backgroundColor: "grey",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 1,
  borderColor: "#1C5988",
  borderStyle: "solid",
  borderRadius: 10,
  bgcolor: "green",
  width: 400,
  height: 235,
  // marginLeft: 10,
  marginTop: 30,
  // marginBottom: 20,
  textAlign: "center",
};

const gradientStyle = {
  background: `linear-gradient(to right, #68BD45, #1C5988)`,
  WebkitBackgroundClip: "text",
  color: "transparent",
  display: "inline-block",
  fontSize: 24,
  fontFamily: "Poppins-SemiBold",
};

const DownloadTxt = {
  fontSize: 16,
  fontFamily: "Poppins-SemiBold",
  color: "#8B979E",
};

const gridTxt1 = {
  color: "#68BD45",
  fontSize: 16,
  fontFamily: "Poppins-SemiBold",
  marginTop: 5,
  marginBottom: 5,
};

const gridTxt2 = {
  color: "white",
  fontSize: 20,
  fontFamily: "Poppins-SemiBold",
};

const copyright = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  color: "#1C5988",
};

function NewFooter() {
  return (
    <div
      style={{
        backgroundColor: "#17232A",
        height: "auto",
        color: "#FFFF",
        paddingTop: "5%",
        paddingBottom: "5%",
      }}
    >
      <Grid container style={GridBody} sx={{ marginBottom: 3 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={GridItem}
          // sx={{ paddingLeft: 5, alignItems: "flex-start"}}
          sx={{
            "@media (max-width: 899px)": {
              alignItems: "center",
              marginBottom: 3,
            },
            "@media (min-width: 899px)": {
              alignItems: "flex-start",
              paddingLeft: 5,
            },
          }}
        >
          <Box className="logoBox" sx={{ marginBottom: 2 }}>
            <img src={Frame} alt="logo" width="48" height="43" />
            <img
              src={Monere}
              alt="logo"
              className="logo-two"
              width="126"
              height="27"
            />
          </Box>
          <Typography style={gradientStyle}>
            Revolutionary digital health and wellness
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={GridItem}
          sx={{
            "@media (max-width: 899px)": {
              alignItems: "center",
              marginBottom: 3,
            },
            "@media (min-width: 899px)": {
              alignItems: "flex-end",
              paddingRight: 5,
            },
          }}
        >
          <Typography sx={{ marginBottom: 1 }} style={DownloadTxt}>
            Download the app from
          </Typography>
          <Box>
            <img src={appstore} alt="logo" width="175" height="54" />
            <img
              src={playstore}
              alt="logo"
              width="175"
              height="52"
              style={{ marginLeft: 10 }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container style={GridBody}>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          style={GridItem}
          // sx={{ paddingLeft: 5, alignItems: "flex-start" }}
          sx={{
            paddingLeft: 5,
            "@media (max-width: 899px)": {
              alignItems: "center",
              marginBottom: 3,
            },
            "@media (min-width: 899px)": {
              alignItems: "flex-start",
            },
          }}
        >
          <Box alignItems="flex-start">
            <CustomLink>Home</CustomLink>
            <CustomLink>What we are solving</CustomLink>
            <CustomLink>Our Solution</CustomLink>
            <CustomLink> Partner with us</CustomLink>
            <CustomLink>Pricings</CustomLink>
            <CustomLink>Who we are</CustomLink>
            <CustomLink>Blogs</CustomLink>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          style={GridItem}
          sx={{
            "@media (max-width: 899px)": {
              alignItems: "center",
            },
            "@media (min-width: 899px)": {
              alignItems: "flex-end",
              paddingRight: 5,
            },
          }}
        >
          <Box>
            <img src={fb} alt="logo" />
            <img src={linkedin} alt="logo" style={{ marginLeft: 10 }} />
            <img src={xlogo} alt="logo" style={{ marginLeft: 10 }} />
            <img src={insta} alt="logo" style={{ marginLeft: 10 }} />
          </Box>
        </Grid>
      </Grid>

      <Grid container style={GridBody} sx={{ paddingLeft: 5, paddingRight: 5 }}>
        <Grid item xs={12} sm={12} md={12} lg={3.5} style={CustGrid}>
          <img src={call} alt="logo" />
          <Typography style={gridTxt1}>Call us at:</Typography>
          <Typography style={gridTxt2}>+1 408-464-2253</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3.5} style={CustGrid}>
          <img src={mail} alt="logo" />
          <Typography style={gridTxt1}>Email us at:</Typography>
          <Typography style={gridTxt2}>hello@monere.ai</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3.5} style={CustGrid}>
          <img src={location} alt="logo" />
          <Typography style={gridTxt1}>Visit us at:</Typography>
          <Typography style={gridTxt2}>
            4121 N Morning View Way.Lehi,
            <br />
            UT,USA,84043
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={GridBody} sx={{ marginTop: 2 }}>
        {/* <Grid style={GridItem} item xs={12} sm={12} md={6} lg={6} sx={{ paddingLeft: 5, alignItems: 'flex-start'}}>
          <Typography sx={{color: '#8B979E'}}>* Data reference: abcd.com | abcd.com</Typography>
        </Grid> */}
        <Grid
          style={GridItem}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            "@media (max-width: 899px)": {
              alignItems: "center",
            },
            "@media (min-width: 899px)": {
              alignItems: "flex-end",
              paddingRight: 5,
            },
          }}
        >
          <Typography style={copyright}>
            Copyright @ 2024 Monere. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default NewFooter;

import React, { useState } from "react";
import cross from "../../assets/Images/cross.png";
import trendImage from "../../assets/Images/trend-image.png";
import emailIcon from "../../assets/Images/Email.png";
import pinIcon from "../../assets/Images/pin.png";
import shareIcon from "../../assets/Images/share.png";
import "./style.css";

export default function DrawerBox(props) {
  const { open, view, edit, mail, trend, close } = props;

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box">
              {view && <h2 className="drawer-title">View Data</h2>}
              {edit && <h2 className="drawer-title">Edit Data</h2>}
              {mail && <h2 className="drawer-title">Invite</h2>}
              {trend && <h2 className="drawer-title">Trend</h2>}
              <div className="cursor-pointer" onClick={close}>
                <img src={cross} alt="cross-icon" className="h-5 w-5" />
              </div>
            </div>

            {view && (
              <div>
                <div className="mt-8">
                  <input placeholder="Name" className="input w-full" />
                  <div className="heading-box mt-5">
                    <input placeholder="Username" className="input w-[22rem]" />
                    <input placeholder="User ID" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Email" className="input w-[22rem]" />
                    <input placeholder="Phone" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Role" className="input w-[22rem]" />
                    <input
                      placeholder="Account Type"
                      className="input w-[22rem]"
                    />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Address" className="input w-[22rem]" />
                    <input placeholder="State" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input
                      placeholder="Allocated Users"
                      className="input w-[22rem]"
                    />
                    <input
                      placeholder="Allocated Tests"
                      className="input w-[22rem]"
                    />
                  </div>
                </div>

                {/* <div className="flex mt-8 mb-12">
                  <label>
                    <input
                      type="radio"
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleOptionChange}
                      className=""
                    />
                    <span className="ml-5 text-sm font-pop400 text-darkGrey">
                      Notify user for email
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                      className="ml-[12rem]"
                    />
                    <span className="ml-5 text-sm font-pop400 text-darkGrey">
                      Notify user for messages
                    </span>
                  </label>
                </div>

                <div className="heading-box mt-5">
                  <button className="footer-btn">Cancel</button>
                  <button className="footer-btn">Add User</button>
                </div> */}
              </div>
            )}
            {edit && (
              <div>
                <div className="mt-8">
                  <input placeholder="Name" className="input w-full" />
                  <div className="heading-box mt-5">
                    <input placeholder="Username" className="input w-[22rem]" />
                    <input placeholder="User ID" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Email" className="input w-[22rem]" />
                    <input placeholder="Phone" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Role" className="input w-[22rem]" />
                    <input
                      placeholder="Account Type"
                      className="input w-[22rem]"
                    />
                  </div>
                  <div className="heading-box mt-5">
                    <input placeholder="Address" className="input w-[22rem]" />
                    <input placeholder="State" className="input w-[22rem]" />
                  </div>
                  <div className="heading-box mt-5">
                    <input
                      placeholder="Allocated Users"
                      className="input w-[22rem]"
                    />
                    <input
                      placeholder="Allocated Tests"
                      className="input w-[22rem]"
                    />
                  </div>
                </div>

                <div className="flex mt-8 mb-12">
                  <label>
                    <input
                      type="radio"
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleOptionChange}
                      className=""
                    />
                    <span className="ml-5 text-sm font-pop400 text-darkGrey">
                      Notify user for email
                    </span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                      className="ml-[12rem]"
                    />
                    <span className="ml-5 text-sm font-pop400 text-darkGrey">
                      Notify user for messages
                    </span>
                  </label>
                </div>

                <div className="heading-box mt-5">
                  <button className="footer-btn">Cancel</button>
                  <button className="footer-btn">Save</button>
                </div>
              </div>
            )}

            {mail && (
              <div>
                <div className="mt-8">
                  <input placeholder="Name" className="input w-full" />
                
                  <textarea placeholder="Type your message" rows={5} className="textarea-box mt-5" />
                </div>


                <div className="heading-box mt-5">
                  <button className="footer-btn">Cancel</button>
                  <button className="footer-btn">Send</button>
                </div>
              </div>
            )}

            {trend && (
              <div className="p-4">
                <div className="flex justify-between">
                  <div>
                    <p className="text-sm font-pop500 text-midGrey">patient</p>
                    <p className="text-2xl font-pop600 text-darkTeal">
                      Afreena Yeasmin
                    </p>
                    <p className="text-sm font-pop500 text-darkGrey">Female</p>
                  </div>
                  <div className="bg-lightBlue rounded p-4 mb-4 flex flex-col">
                    <p className="text-xs font-pop400 text-darkGrey mb-2">
                      Last tested on{" "}
                      <span className="font-pop600">Mon, 12 Aug 2023</span>
                    </p>
                    <p className="text-sm font-pop500 text-darkGrey">
                      Hemoglobin level is{" "}
                      <span className="text-lightRed">8.81g/dl</span>{" "}
                      <span className="ml-1 text-white px-2 rounded-3xl bg-red">
                        Low
                      </span>
                    </p>
                  </div>
                </div>
                <div className="mt-8">
                  <p className="text-base font-pop600 text-darkTeal mb-8">
                    Trend Analysis
                  </p>
                </div>
                <div>
                  <img src={trendImage} alt="trendImage" className="mb-4" />
                  <div className="my-10">
                    <p className="text-sm font-pop500 text-midGrey">
                      X Axis: Months, Y Axis: Hemoglobin level (g/dL)
                    </p>
                    <p className="text-sm font-pop500 text-primeGreen">
                      Normal<span className="text-red ml-4">Low</span>
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center">
                      <img
                        src={shareIcon}
                        alt="shareIcon"
                        className="h-5 w-5"
                      />
                      <p className="text-sm font-pop500 ml-2">Share Report</p>
                    </div>
                    <div className="flex items-center">
                      <img src={pinIcon} alt="pinIcon" className="h-5 w-5" />
                      <p className="text-sm font-pop500 ml-2">
                        Pin to Watchlist
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img
                        src={emailIcon}
                        alt="emailIcon"
                        className="h-5 w-5"
                      />
                      <p className="text-sm font-pop500 ml-2">
                        Invite for consultation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import Select from 'react-select';
import india from '../../assets/Images/india.png';
import usa from '../../assets/Images/usa.png';
import { bgcolor, border, borderColor, borderRadius, display, textAlign } from '@mui/system';
import { BorderColor } from '@mui/icons-material';


const options = [
  { value: 'india', label: <img src={india} alt="Option 1" style={{ width: '25px', height: '25px' }} /> },
  { value: 'usa', label: <img src={usa} alt="Option 2" style={{ width: '25px', height: '25px' }} /> },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // textAlign: 'center',
    // marginLeft: 10
    
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderColor: '#1C5988',
    borderWidth: 2,
    borderRadius: 8,
    '&:hover': {
      borderColor: '#1C5988',
    },
  }),
};

const CountryMenu = () => {
  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={options[0]}
    />
  );
};

export default CountryMenu;
import React, { useState } from "react";
import lefteye from "../../assets/Images/left-eye.png";
import righteye from "../../assets/Images/right-eye.png";
import "./style.css";

function CaptureEyeTab() {
  const [activeTab, setActiveTab] = useState(1);

  function handleClick(tabNum) {
    setActiveTab(tabNum);
  }
  return (
    <div>
      <div className="w-full flex flex-row">
        <button
          className={activeTab === 1 ? "tab-list active" : "tab-list"}
          onClick={() => handleClick(1)}
        >
          Left Eye
        </button>
        <button
          className={activeTab === 2 ? "tab-list active" : "tab-list"}
          onClick={() => handleClick(2)}
        >
          Right Eye
        </button>
      </div>
      <div className="mt-5">
        {activeTab === 1 && (
          <div>
            <p className="img-txt">Add photo of lower eyelid (Left)</p>
            <div className="img">
              <img src={lefteye} alt="lefteye" />
            </div>
            <div className="btn-box">
              <button className="img-btn">Upload Photo</button>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <p className="img-txt">Add photo of lower eyelid (Left)</p>
            <div className="img">
              <img src={righteye} alt="lefteye" />
            </div>
            <div className="btn-box">
              <button className="img-btn">Upload Photo</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CaptureEyeTab;

import React from "react";
import cross from "../../assets/Images/cross.png";
import lefteye from "../../assets/Images/left-eye.png";
import righteye from "../../assets/Images/right-eye.png";
import Timeline from "../Timeline";
import share from "../../assets/Images/share.png";
import pin from "../../assets/Images/pin.png";
import email from "../../assets/Images/Email.png";
import "./style.css";

function TestReportBox(props) {
  const { open, close } = props;
  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box">
              <p className="title">Hb Test Report</p>
              <div className="p-1" onClick={close}>
                <img src={cross} alt="cross-icon" height="20" width="20" />
              </div>
            </div>

            <div className="mt-10 mb-5">
              <input placeholder="Name" className="input w-full" />
            </div>

            <div className="heading-box mb-8">
              <div className="flex flex-col">
                <p className="text-one">Hemoglobin Level is</p>
                <p className="text-two">8.81g/dL <span className="text-three">Low</span></p>
                <p className="text-one">Normal range 13 to 16 g/dL</p>
                <p className="text-four">Tested on Mon, 12 Aug 2024</p>
              </div>
              <div className="heading-box">
                <img src={righteye} height="121" width="112" alt="right-eye" />
                <img
                  src={lefteye}
                  height="121"
                  width="112"
                  alt="left-eye"
                  style={{ marginLeft: 20 }}
                />
              </div>
            </div>
            <Timeline />

            <button className="add-patient-button">Add this patient</button>

            <div className="heading-box mt-5">
              <button className="heading-box">
                <img src={share} alt={share} />
                <p className="grid-text">Share Report</p>
              </button>
              <button className="heading-box">
                <img src={pin} alt={pin} />
                <p className="grid-text">Pin to Watchlist</p>
              </button>
              <button className="heading-box">
                <img src={email} alt={email} />
                <p className="grid-text">Invite for consultation</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TestReportBox;

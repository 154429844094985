import React from "react";
import "./style.css";
import Data from "../../SampleDateTwo.json";
import edit from "../../assets/Images/Edit.png";
import attach from "../../assets/Images/attach.png";
import email from "../../assets/Images/Email.png";
import phone from "../../assets/Images/Phone.png";
import AddDistributorBox from "../AddDistributorBox";

function DistributorMenu() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [addDistributor, setAddDistributor] = React.useState(false);

  function addDistributorFun() {
    setAddDistributor(!addDistributor);
    setDrawerOpen(!drawerOpen);
  }

  //   function viewFun() {
  //     setView(true);
  //     setEdit(false);
  //     setMail(false);
  //     setTrend(false);
  //     setDrawerOpen(true);
  //   }
  //   function editFun() {
  //     setEdit(true);
  //     setView(false);
  //     setMail(false);
  //     setTrend(false);
  //     setDrawerOpen(true);
  //   }
  //   function mailFun() {
  //     setMail(true);
  //     setView(false);
  //     setEdit(false);
  //     setTrend(false);
  //     setDrawerOpen(true);
  //   }
  //   function trendFun() {
  //     setTrend(true);
  //     setView(false);
  //     setEdit(false);
  //     setMail(false);
  //     setDrawerOpen(true);
  //   }

  //   function startHb() {
  //     setHbtest(!hbtest);
  //   }
  function toggleDrawer() {
    setDrawerOpen(false);
  }
  return (
    <div className="mt-8">
      <div className="first mx-5">
        <p className="title">Dashboard</p>
        <button className="add-distributor-btn" onClick={addDistributorFun}>
          Add Distributor
        </button>
      </div>
      <AddDistributorBox open={drawerOpen} close={toggleDrawer} />

      <hr class="border-t border-lightGrey mt-10" />

      <div className="mx-5">
        <div className="grid-body">
          <div className="grid-item col-span-2">
            <p className="text-2xl font-pop600">Distributor List</p>
          </div>
          <div className="grid-item col-span-3 justify-end">
            <input
              placeholder="Search by name, email, etc"
              // onChange={(e) => setSearch(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="grid-item col-span-2 justify-end">
            <p className="filter-text">Sort By</p>
            <input placeholder="Country" className="sort-input" />
            <input placeholder="Status" className="sort-input" />
            <input placeholder="Date" className="sort-input" />
          </div>
        </div>

        <div className="grid grid-cols-5 my-3">
          <p className="list-title text-left ml-5">Distributor</p>
          <p className="list-title">Organization</p>
          <p className="list-title">Country</p>
          <p className="list-title">Status</p>
        </div>

        <ul className="t-body">
          {Data.map((data, i) => (
            <li className="table-list">
              <p className="list-value text-left ml-5">{data.distributor}</p>
              <p className="list-value">{data.org}</p>
              <p className="list-value">{data.country}</p>
              <p className="list-value">{data.status}</p>
              <div className="flex flex-row justify-end mr-5">
                <button>
                  <img src={edit} alt="edit" className="ml-4" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DistributorMenu;

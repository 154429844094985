import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  styled,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material";
import Frame from "../../assets/Images/Frame.png";
import Monere from "../../assets/Images/Monere.png";
import "../NewHeader/style.css";
import CountryMenu from "../CountryMenu";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import cross from "../../assets/Images/cross.png";
import { useNavigate } from "react-router-dom";

const CustomLink = styled("Button")({
  border: "none",
  boxShadow: "none",
  marginRight: 20,
  backgroundColor: "transparent",
  color: "#fff",
  fontFamily: "Poppins-SemiBold",
  fontSize: 16,
});

const DrawerLink = styled("Button")({
  border: "none",
  boxShadow: "none",
  marginRight: 20,
  backgroundColor: "transparent",
  color: "#1C5988",
  width: "100%",
  marginTop: 10,
  marginBottom: 10,
});

const toolbar = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

function NewHeader() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [elevation, setElevation] = React.useState(0);
  const [bgColor, setBgColor] = React.useState("transparent");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setElevation(isScrolled ? 4 : 0);
    setBgColor(isScrolled ? "#17232A" : "transparent");
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  function goToHome() {
    window.open("https://www.futuredata.info/", "_blank");
  }
  const navigate = useNavigate();

  function goToLogin() {
    navigate("/login");
  }

  function goToUnsubscribe() {
    navigate("/unsubscribe");
  }

  return (
    <>
      <AppBar elevation={elevation} sx={{ backgroundColor: bgColor }}>
        <Toolbar style={toolbar}>
          <Box onClick={goToHome} className="logoBox">
            <img src={Frame} alt="logo1" width="48" height="43" />
            <img
              src={Monere}
              alt="logo2"
              className="logo-two"
              width="126"
              height="27"
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            <CustomLink>Home</CustomLink>
            <CustomLink>What we are solving</CustomLink>
            <CustomLink>Products</CustomLink>
            <CustomLink> Partners</CustomLink>
            <CustomLink>Pricings</CustomLink>
            <CustomLink>Resources</CustomLink>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            <CountryMenu />
            <Button
              variant="contained"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                width: 130,
                height: 41,
                color: "white",
                marginLeft: 1,
                backgroundColor: "#1C5988",
                fontFamily: "Poppins-SemiBold",
                fontSize: 14,
              }}
            >
              My Monere
            </Button>
          </Box>
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ marginRight: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen}>
        <Box
          sx={{
            width: 600,
            padding: 5,
          }}
        >
          <Box
            sx={{ position: "absolute", right: 0, top: 0, padding: 1 }}
            onClick={toggleDrawer}
          >
            <img src={cross} alt="cross-icon" height="20" width="20" />
          </Box>
          <DrawerLink>Home</DrawerLink>
          <DrawerLink className="H2">What we are solving</DrawerLink>
          <DrawerLink className="H2">Our Products</DrawerLink>
          <DrawerLink className="H2">Our Partners</DrawerLink>
          <DrawerLink className="H2">Pricings</DrawerLink>
          <DrawerLink className="H2">Resources</DrawerLink>
          <DrawerLink className="H2">Login</DrawerLink>
          <DrawerLink className="H2">Unsubscribe</DrawerLink>
          <Box sx={{ textAlign: "center", padding: 1, marginBottom: 2 }}>
            <CountryMenu />
          </Box>
        </Box>
      </Drawer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiMenu-paper": {
            backgroundColor: "#153B50",
            color: "white",
            width: 130,
            borderRadius: 4,
          },
        }}
      >
        <MenuItem
          onClick={goToLogin}
          sx={{
            borderBottom: "2px solid #1C5988",
            paddingBottom: 2,
          }}
        >
          Login
        </MenuItem>
        <MenuItem onClick={goToUnsubscribe} sx={{ paddingTop: 2 }}>
          Unsubscribe
        </MenuItem>
      </Menu>
    </>
  );
}
export default NewHeader;

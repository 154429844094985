import React, { useState } from "react";
import "./style.css";

function Tabs() {
  const [activeTab, setActiveTab] = useState(1);
  const [emailId, setEmailId] = React.useState(true);
  const [pId, setpId] = React.useState(false);

  function handleClick(tabNum) {
    setActiveTab(tabNum);
  }
  function selectEmail() {
    setEmailId(true);
    setpId(false);
  }
  function selectId() {
    setpId(true);
    setEmailId(false);
  }

  return (
    <div>
      <div className="w-full flex flex-row">
        <button
          className={activeTab === 1 ? "tab-list active" : "tab-list"}
          onClick={() => handleClick(1)}
        >
          Personal Details
        </button>
        <button
          className={activeTab === 2 ? "tab-list active" : "tab-list"}
          onClick={() => handleClick(2)}
        >
          Address
        </button>
      </div>
      <div className="mt-5">
        {activeTab === 1 && (
          <div>
            <button className={emailId ? "tabBtn active-tab" : "tabBtn"} onClick={selectEmail}>
              Email
            </button>
            <button className={pId ? "tabBtn active-tab ml-2" : "tabBtn ml-2"} onClick={selectId}>
              Patient ID
            </button>
            <div className="mt-3 flex flex-row justify-between">
              {emailId && (
                <input
                  placeholder="Enter patient email address"
                  className="tabInput"
                />
              )}
              {pId && (
                <input placeholder="Enter patient ID" className="tabInput" />
              )}
              <input placeholder="Enter Birthdate" className="tabInput" />
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <input placeholder="Address" className="tabInput w-full" />
            <div className="flex flex-row justify-between mt-3">
              <input placeholder="State" className="tabInput" />
              <input placeholder="pincode" className="tabInput" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tabs;

import React from "react";
import Frame from "../../assets/Images/Frame.png";
import Monere from "../../assets/Images/Monere.png";
import appstore from "../../assets/Images/appstore.png";
import playstore from "../../assets/Images/playstore.png";
import fb from "../../assets/Images/fb.png";
import insta from "../../assets/Images/insta.png";
import xlogo from "../../assets/Images/logo-x-.png";
import linkedin from "../../assets/Images/linkedin.png";
import call from "../../assets/Images/call.png";
import mail from "../../assets/Images/mail.png";
import location from "../../assets/Images/location.png";
import "../Footer/style.css";

const gradientStyle = {
  background: `linear-gradient(to right, #68BD45, #1C5988)`,
  WebkitBackgroundClip: "text",
  color: "transparent",
  display: "inline-block",
  fontSize: 24,
  fontFamily: "Poppins-SemiBold",
};

function Footer() {
  return (
    <div className="footer-box">
      <div className="row-one">
        <div className="row-one-col">
          <div className="logo-box">
            <img src={Frame} alt="logo" width="48" height="43" />
            <img
              src={Monere}
              alt="logo"
              className="logo-two"
              width="126"
              height="27"
            />
          </div>
          <p style={gradientStyle}>Revolutionary digital health and wellness</p>
        </div>

        <div className="row-one-col">
          <p className="download-text">Download the app from</p>
          <div className="flex">
            <img src={appstore} alt="logo" width="175" height="54" />
            <img
              src={playstore}
              alt="logo"
              width="175"
              height="52"
              style={{ marginLeft: 10 }}
            />
          </div>
        </div>
      </div>

      <div className="footer-links">
        <ul>
          <list className="navlink">
            <button className="text-midGrey">Home</button>
          </list>
          <list className="navlink">
            <button className="text-midGrey">What we are solving</button>
          </list>
          <list className="navlink">
            <button className="text-midGrey">Our Product</button>
          </list>
          <list className="navlink">
            <button className="text-midGrey">Our Partners</button>
          </list>
          {/* <list className="navlink">
            <button className="text-midGrey">Our Solution</button>
          </list>
          <list className="navlink">
            <button className="text-midGrey">Partner with us</button>
          </list> */}
          {/* <list className="navlink">
            <button className="text-midGrey">Pricings</button>
          </list> */}
          {/* <list className="navlink">
            <button className="text-midGrey">Who we are</button>
          </list> */}
          <list className="navlink">
            <button className="text-midGrey">Blog</button>
          </list>

          <list className="navlink">
            <button className="text-midGrey">Privacy Policy</button>
          </list>
          <list className="navlink">
            <button className="text-midGrey">Terms & Conditions</button>
          </list>
        </ul>

        <ul>
          <list>
            <button>
              <img src={fb} alt="logo" className="ml-5" />
            </button>
          </list>
          <list>
            <button>
              <img src={linkedin} alt="logo" className="ml-5" />
            </button>
          </list>
          <list>
            <button>
              <img
                src={xlogo}
                alt="logo"
                className="ml-5"
                height="26"
                width="26"
              />
            </button>
          </list>
          <list>
            <button>
              <img src={insta} alt="logo" className="ml-5" />
            </button>
          </list>
        </ul>
      </div>

      <div className="px-4 flex justify-between">
        <div className="gridbox">
          <img src={call} alt="logo" />
          <p className="textOne">Call us at:</p>
          <p className="textTwo">+1 408-464-2253</p>
        </div>

        <div className="gridbox">
          <img src={mail} alt="logo" />
          <p className="textOne">Email us at:</p>
          <p className="textTwo">hello@monere.ai</p>
        </div>

        <div className="gridbox">
          <img src={location} alt="logo" />
          <p className="textOne">USA</p>
          <p className="textTwo text-center">
            4121 N Morning View Way.Lehi,
            <br />
            UT,USA,84043
          </p>
        </div>

        <div className="gridbox">
          <img src={location} alt="logo" />

          <p className="textOne">India</p>
          <p className="textTwo text-center">
            E-6/1, DF Block, Purba Abasan,
            <br />
            1582/1, Rajdanga Main Road,
            <br />
            Kolkata 700107, West Bengal, India
          </p>
        </div>
      </div>

      <div className="copyright">
        <p className="copyright-text">
          Monere AI Private Limited.
          <br />
          Copyright @ 2024 Monere. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;

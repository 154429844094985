import React from "react";
import "../../Packages/CardLayout/style.css";
import check from "../../assets/Images/tick1.png";
import { useNavigate } from "react-router-dom";

function CardLayout(props) {
  const {
    planName,
    list,
    price,
    limit,
    tagline,
    primaryBtn,
    primaryBtnFun,
    secondaryBtn,
    secondaryBtnFun,
    extrabtn,
  } = props;

  const navigate = useNavigate();
  function goToSales() {
    navigate("/Sales");
  }
  return (
    <div className="custCard">
      <div className="box-one">
        <p className="plan-title">{planName}</p>
        {/* <p className="plan-price">{price}<span className="text-base text-darkTeal"> / 6 months</span></p> */}
        <p className="price">{price}</p>
        {/* <p className="plan-validity">{limit}</p> */}
        {planName === "Enterprise" ? (
          <p className="plan-validity cursor-pointer" onClick={goToSales}>{limit}</p>
        ) : (
          <p className="plan-validity">{limit}</p>
        )}
      </div>

      <div className="w-[23rem] pl-8 py-5">
        <p className="plan-includes text-darkTeal">{tagline}</p>
        <p className="plan-list">
          {list?.map((value) => (
            <div className="items">
              {" "}
              <img src={check} alt="check" className="w-4 h-4 mr-3 mt-1" />
              {value}
            </div>
          ))}
        </p>
      </div>

      <div className="btn-box">
        {extrabtn ? (
          <button className="button" onClick={secondaryBtnFun}>
            {secondaryBtn}
          </button>
        ) : (
          <button className="button" onClick={primaryBtnFun}>
            {primaryBtn}
          </button>
        )}
      </div>
    </div>
  );
}

export default CardLayout;

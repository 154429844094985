import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Card,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../style/MyCard.css";

const input = {
  margin: 2,
  width: {
    lg: 500,
    md: 450,
    sm: 350,
    xs: 250,
  },
};

function ContactSales() {
  const navigate = useNavigate();

  function goToDownloadLink() {
    navigate("/DownloadLink");
  }

  function goToYoutube() {
    window.open("https://www.youtube.com/watch?v=wLMgR0VPZhc", "_blank");
  }

  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: 12 }}>
        <Typography className="heading">Contact Sales Person</Typography>
      </Box>
      <Box
        component="div"
        sx={{
          marginTop: 5,
          marginBottom: 10,
          backgroundImage: "linear-gradient(to right, #2295C5, #24A984)",
          height: "auto", // Set minimum height to fill the viewport
        }}
      >
        <Container fixed sx={{ pt: 5, pb: 5 }}>
          <Card
            sx={{
              p: 3,
              boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid container>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box
                  component="form"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      color: "#034C8C",
                    }}
                  >
                    Fill the details below:
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    sx={input}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    sx={input}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    sx={input}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    sx={input}
                  />

                  <Button
                    variant="contained"
                    className="btn"
                    onClick={goToDownloadLink}
                    sx={input}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>

              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src="assets/niada-youtube.jpg"
                    alt="youtube Link"
                    class="youtube-img"
                    style={{
                      width: 350,
                      height: 250,
                      borderRadius: 5,
                      paddingTop: 50,
                    }}
                    onClick={goToYoutube}
                  />
                  <Box>
                    <Typography
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        textAlign: "center",
                        fontSize: 20,
                        marginTop: 5,
                        fontWeight: "450",
                        color: "#034C8C",
                      }}
                    >
                      <CallIcon /> : +91 1234567819 <br />
                    </Typography>
                    <Typography
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        textAlign: "center",
                        fontSize: 20,
                        marginTop: 1,
                        fontWeight: "450",
                        color: "#034C8C",
                      }}
                    >
                      <EmailIcon /> : hello@futuredata.info
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default ContactSales;

import React from "react";
import cross from "../../assets/Images/cross.png";
import "./style.css";
import Tabs from "../Tabs";

function AddPatientBox(props) {
  const { open, close } = props;
  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box">
              <p className="title">Add Patient</p>
              <div className="p-1" onClick={close}>
                <img src={cross} alt="cross-icon" height="20" width="20" />
              </div>
            </div>
            <div className="mt-10">
              <input required placeholder="Name" className="input w-full" />
              <div>
                <Tabs/>
              </div>
              <div>
                <p className="history-title">Medical History</p>
                <div className="heading-box">
                  <input
                    required
                    placeholder="Lab Hb"
                    className="input w-[22rem]"
                  />
                  <button className="record-btn">
                    Record Audio Clip
                  </button>
                </div>
                <textarea
                  className="textarea-box"
                  rows="3"
                  placeholder="Enter medical history manually"
                />
                <button className="upload-btn">
                  Upload attachment
                </button>
              </div>
              <div className="heading-box mt-8">
                <button className="footer-btn">
                  Cancel
                </button>
                <button className="footer-btn">
                  Add Patient
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddPatientBox;

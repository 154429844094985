import React from "react";
import dots from "../../assets/Images/dots.png";
import viewIcon from "../../assets/Images/view.png";
import editIcon from "../../assets/Images/edit-new.png";
import emailIcon from "../../assets/Images/mail-box.png";
import trendIcon from "../../assets/Images/trend-new.png";
import binIcon from "../../assets/Images/bin.png";
import "./style.css";
import DrawerBox from "../DrawerBox";

function ThreeDotMenu() {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [trend, setTrend] = React.useState(false);

  function handleClick() {
    setMenuOpen(!menuOpen);
  }

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleViewClick = () => {
    handleClose();
    setView(true);
    setEdit(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  };

  const handleEditClick = () => {
    handleClose();
    setEdit(true);
    setView(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  };
  const handleMailClick = () => {
    handleClose();
    setMail(true);
    setView(false);
    setEdit(false);
    setTrend(false);
    setDrawerOpen(true);
  };

  const handleTrendClick = () => {
    handleClose();
    setTrend(true);
    setView(false);
    setEdit(false);
    setMail(false);
    setDrawerOpen(true);
  };

  function toggleDrawer() {
    setDrawerOpen(false);
  }
  return (
    <div>
      <button onClick={handleClick}>
        <img src={dots} alt="three-dot" height="25" width="25" />
      </button>
      {menuOpen && (
        <ul className="absolute px-5 py-3 bg-white shadow-2xl rounded right-[29rem]">
          <li onClick={handleViewClick} className="three-dot-menu-item">
            <img src={viewIcon} alt="view" className="icon" /> View
          </li>
          <li onClick={handleEditClick} className="three-dot-menu-item">
            <img src={editIcon} alt="edit" className="icon" /> Edit
          </li>
          <li onClick={handleMailClick} className="three-dot-menu-item">
            <img src={emailIcon} alt="mail" className="icon" /> Email
          </li>
          <li onClick={handleTrendClick} className="three-dot-menu-item">
            <img src={trendIcon} alt="trend" className="icon" /> Trends
          </li>
          <li className="three-dot-menu-item">
            <img src={binIcon} alt="bin" className="icon" /> Delete
          </li>
        </ul>
      )}
      <DrawerBox
        open={drawerOpen}
        close={toggleDrawer}
        view={view}
        edit={edit}
        mail={mail}
        trend={trend}
      />
    </div>
  );
}

export default ThreeDotMenu;

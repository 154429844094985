import React from "react";
import cross from "../../assets/Images/cross.png";
import "./style.css";

function AddCustomerBox(props) {
  const { open, close } = props;

  const [selectedOption, setSelectedOption] = React.useState("");
  const options = ["Option 1", "Option 2", "Option 3"];

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box">
              <p className="title">Add/Select Organization</p>
              <div className="p-1" onClick={close}>
                <img src={cross} alt="cross-icon" height="20" width="20" />
              </div>
            </div>

            <div className="mt-8">
              <input placeholder="Name" className="input w-full" />
              <div className="flex mt-2">
                <p className="font-pop500 text-darkGrey text-base">Start Date</p>
                <p className="font-pop500 text-darkGrey text-base ml-[18rem]">End Date</p>
              </div>
              <div className="heading-box mt-2">
                <input type="date" className="input w-[22rem]" />
                <input type="date" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-2">
              <select value={selectedOption} onChange={handleChange} className="input w-[22rem]">
                  <option value="" disabled hidden className="option">
                   Choose Country
                  </option>
                  {options.map((option, index) => (
                    <option key={index} value={option} className="option">
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="heading-box mt-5">
              <p className="title">Add DMU</p>
            </div>

            <div className="mt-8">
              <input placeholder="Name" className="input w-full" />
              <div className="heading-box mt-2">
                <input placeholder="Address" className="input w-[22rem]" />
                <input placeholder="Country" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-2">
                <input placeholder="Email" className="input w-[22rem]" />
                <input placeholder="Phone" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-2">
                <input
                  placeholder="Active Status"
                  className="input w-[22rem]"
                />
                <input
                  placeholder="Upload Contract"
                  className="input w-[22rem]"
                />
              </div>
              <textarea
                placeholder="Note(Optioanl)"
                rows={2}
                className="textarea-box"
              />
            </div>

            <div>
              <p className="title text-left mt-5">Add Payment Details</p>
            </div>

            <div className="mt-4">
              <div className="heading-box mt-2">
                <select value={selectedOption} onChange={handleChange} className="input w-[22rem]">
                  <option value="" disabled hidden className="option">
                    Payment Method
                  </option>
                  {options.map((option, index) => (
                    <option key={index} value={option} className="option">
                      {option}
                    </option>
                  ))}
                </select>
                <input placeholder="Term" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-2">
                <input
                  placeholder="Minimum Amount"
                  className="input w-[22rem]"
                />
                <input placeholder="Renewal" className="input w-[22rem]" />
              </div>
              <textarea
                placeholder="Commission Payment Note"
                rows={2}
                className="textarea-box"
              />
            </div>

            <div className="heading-box mt-5">
              <button className="footer-btn">Cancel</button>
              <button className="footer-btn">Add Customer</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddCustomerBox;

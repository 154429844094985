import React from "react";
import Frame from "../../assets/Images/Frame.png";
import Monere from "../../assets/Images/Monere.png";
import dashIcon from "../../assets/Images/dashboard-icon.png";
import userIcon from "../../assets/Images/users-icon.png";
import trendIcon from "../../assets/Images/graph-icon.png";
import subscribeIcon from "../../assets/Images/subscription-icon.png";
import logoutIcon from "../../assets/Images/logout-icon.png";


function Sidebar(props) {
  const {
    isDash,
    setIsDash,
    isUser,
    setIsUser,
    setSubs,
    setIsSubs,
    setPatients,
    setIsPatients,
    setDistributor,
    setIsDistributor,
    setCustomer,
    setIsCustomer,
    pricingPlan,
    setPricingPlan,
    admin,
    setAdmin,
    superUser,
    setSuperUser,
    superAdmin,
    setSuperAdmin,
  } = props;

  const [selectedItem, setSelectedItem] = React.useState("dashboard");

  function dashBtn() {
    setIsDash(true);
    setSelectedItem("dashboard");
    setIsUser(false);
    setIsSubs(false);
  }
  function usersBtn() {
    setIsUser(true);
    setSelectedItem("users");
    setIsDash(false);
    setIsSubs(false);
  }
  function subsBtn() {
    setIsSubs(true);
    setSelectedItem("subs");
    setIsDash(false);
    setIsUser(false);
  }
  function PatientsBtn() {
    setIsPatients(true);
    setSelectedItem("patients");
    // setIsSubs(true);
    // setIsDash(false);
    // setIsUser(false);
  }
  function DistributorBtn(){
    setIsDistributor(true);
    setIsCustomer(false);
    setPricingPlan(false);
    setSelectedItem("distributor");
  }
  function CustomerBtn(){
    setIsCustomer(true);
    setIsDistributor(false);
    setPricingPlan(false);
    setSelectedItem("customer");
  }
  function PlanBtn(){
    setPricingPlan(true);
    setIsCustomer(false);
    setIsDistributor(false);
    setSelectedItem("pricing");
  }
  function trendBtn() {
    setSelectedItem("trend");
    // setIsTrend(true);
  }
  function logoutBtn() {
    setSelectedItem("logout");
  }

  return (
    <div className="bg-lightGrey w-72 h-[44.375rem]">
      <div className="flex items-center pl-7 pt-8">
        <img src={Frame} alt="logo1" width="48" height="43" />
        <img
          src={Monere}
          alt="logo2"
          className="ml-3"
          width="126"
          height="27"
        />
      </div>

      {superUser && (
        <ul className="pt-20">
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={dashBtn}
          >
            <img src={dashIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Dashboard
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={usersBtn}
          >
            <img src={userIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Users
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={subsBtn}
          >
            <img src={subscribeIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Subscription
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={trendBtn}
          >
            <img src={trendIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Trends
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={logoutBtn}
          >
            <img src={logoutIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Logout
            </span>
          </li>
        </ul>
      )}

      {admin && (
        <ul className="pt-20">
          <li className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne">
            <img src={dashIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Dashboard
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={PatientsBtn}
          >
            <img src={userIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Patients
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={logoutBtn}
          >
            <img src={logoutIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Logout
            </span>
          </li>
        </ul>
      )}

      {superAdmin && (
        <ul className="pt-20">
          <li className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne" onClick={DistributorBtn}>
            <img src={dashIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Distributors
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={CustomerBtn}
          >
            <img src={userIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              DMU Users
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={CustomerBtn}
          >
            <img src={userIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Partners
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={PlanBtn}
          >
            <img src={logoutIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Pricing Plans
            </span>
          </li>
          <li
            className="flex mb-5 items-center px-4 py-1.5 mx-4 hover:bg-greyOne"
            onClick={logoutBtn}
          >
            <img src={logoutIcon} alt="dashIcon" />
            <span className="pl-4 font-pop600 text-base text-darkGrey">
              Logout
            </span>
          </li>
        </ul>
      )}

      <div className="left-8 bottom-10 absolute">
        <p className="text-xs font-pop500 text-midGrey">
          Copyright @ 2024 Monere.
          <br />
          All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Sidebar;

import React from "react";
import { Drawer, Box, Typography, Grid } from "@mui/material";
import cross from "../../assets/Images/cross.png";
import trendImage from "../../assets/Images/trend-image.png";
import emailIcon from "../../assets/Images/Email.png";
import pinIcon from "../../assets/Images/pin.png";
import shareIcon from "../../assets/Images/share.png";

const body = {
  width: 800,
  paddingX: 4,
  marginTop: 3
};
const title = {
  fontSize: 24,
  fontFamily: "Poppins-SemiBold",
};
const tag = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  color: "#8B979E",
};
const name = {
  fontSize: 24,
  fontFamily: "Poppins-SemiBold",
  color: "#153B50",
};
const sex = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  color: "#2A2A2A",
};
const hbBox = {
  backgroundColor: "#429EA633",
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const testTxt1 = {
  fontSize: 10,
  fontFamily: "Poppins-Regular",
  color: "#2A2A2A",
  marginBottom: 0.5,
};
const testTxt2 = {
  fontSize: 12,
  fontFamily: "Poppins-Medium",
  color: "#353535",
};
const number = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  color: "#F25C54",
};
const indicator = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  backgroundColor: "#CC2936",
  color: "#FFFFFF",
  marginLeft: 3,
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 10,
};
const subtitle = {
  marginTop: 3,
  fontSize: 16,
  fontFamily: "Poppins-SemiBold",
  color: "#153B50",
  marginBottom: 3,
};
const lebel = {
  fontSize: 14,
  fontFamily: "Poppins-Medium",
  color: "#8B979E",
};
const gridItem = {
   display: 'flex',
   flexDirection: 'row',
   // backgroundColor: 'pink',
   justifyContent: 'center',
   alignItems: 'center'
}
const gridBody = {
   marginTop: 3,
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'space-between'
}
const gridTxt = {
   marginLeft: 2,
   fontSize: 14,
   fontFamily: 'Poppins-Medium',
   color: '#153B50'
}

export default function DrawerBox(props) {
  const { anchor, open, view, edit, mail, trend, close } = props;
  return (
    <div>
      <Drawer anchor={anchor} open={open} sx={body} close={close}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            //   bgcolor: "red",
              padding: 4,
            }}
            // onClick={close}
          >
            {view && <Typography sx={title}>View Data</Typography>}
            {edit && <Typography sx={title}>Edit Data</Typography>}
            {mail && <Typography sx={title}>Invite</Typography>}
            {trend && <Typography sx={title}>Trend</Typography>}
            <Box sx={{ position: "absolute", right: 0, top: 0, padding: 4 }} onClick={close} >
              <img src={cross} alt="cross-icon" height="20" width="20" />
            </Box>
          </Box>

          {view && (
            <Box sx={body}>
              <Typography>This is View</Typography>
            </Box>
          )}
          {edit && (
            <Box sx={body}>
              <Typography>This is Edit</Typography>
            </Box>
          )}

          
          {mail && (
            <Box sx={body}>
              <Typography>This is Mail</Typography>
            </Box>
          )}


          {trend && (
            <Box sx={body}>
              <Grid container>
                <Grid item lg={6}>
                  <Typography sx={tag}>patient</Typography>
                  <Typography sx={name}>Afreena Yeasmin</Typography>
                  <Typography sx={sex}>Female</Typography>
                </Grid>
                <Grid item lg={6} sx={hbBox}>
                  <Typography sx={testTxt1}>
                    Last tested on{" "}
                    <span style={{ fontFamily: "Poppins-SemiBold" }}>
                      Mon, 12 Aug 2023
                    </span>
                  </Typography>
                  <Typography sx={testTxt2}>
                    Hemoglobin level is <span style={number}>8.81g/dl</span>{" "}
                    <span style={indicator}>Low</span>
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Typography sx={subtitle}>Trend Analysis</Typography>
              </Box>
              <Box>
                <img
                  src={trendImage}
                  alt={trendImage}
                />
                <Box sx={{marginTop: 3}}>
                  {" "}
                  <Typography sx={lebel}>
                    X Axis: Months, Y Axis: Hemoglobin level (g/dL)
                  </Typography>
                  <Typography sx={{...lebel, color: '#7AC74F'}}>
                    Normal<span style={{color: '#CC2936', marginLeft: 20}}>Low</span>
                  </Typography>
                </Box>
                <Grid container sx={gridBody}>
                  <Grid item lg={3.5} sx={{...gridItem, justifyContent: 'flex-start'}}>
                     <img src={shareIcon} alt={shareIcon} />
                     <Typography sx={gridTxt}>Share Report</Typography>
                  </Grid>
                  <Grid item lg={3.5} sx={gridItem}>
                     <img src={pinIcon} alt={pinIcon} />
                     <Typography sx={gridTxt}>Pin to Watchlist</Typography>
                  </Grid>
                  <Grid item lg={3.5} sx={{...gridItem, justifyContent: 'flex-end'}}>
                     <img src={emailIcon} alt={emailIcon} />
                     <Typography sx={gridTxt}>Invite for consultation</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </div>
  );
}

import React, { useState } from "react";
import tick from "../../assets/Images/tick.png";
import "../ContactBox/style.css";

function ContactBox() {
  const [isSubmit, setIsSubmit] = useState(false);

  function submit() {
    setIsSubmit(true);
  }

  return (
    <div>
      {isSubmit ? (
        <div className="box">
          <div className="flex justify-center">
            <img src={tick} alt="logo" className="text-center" />
          </div>
          <div className="inner-box">
            <p className="thankyou-text">
              Thank you for your submission. Our team will review it promptly
              and get back to you as soon as possible. Your patience in the
              meantime is highly appreciated.
            </p>
            <p className="heading-two">
              For any further queries contact :
            </p>
            <div className="info">
              <p className="text mr-1">Call us at : </p>
              <p className="text"> +1 408-464-2253</p>
            </div>
            <div className="info">
              <p className="text mr-1">Email us at : </p>
              <p className="text"> hello@monere.ai</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="box">
          <div>
            <p className="heading">Fill the information below:</p>
          </div>
          <div className="inner-box">
            <input className="input" placeholder="Name" />
            <div className="footer">
              <input
                className="input"
                style={{ width: "50%", marginRight: 5 }}
                placeholder="Email"
              />
              <input
                className="input"
                style={{ width: "50%", marginLeft: 5 }}
                placeholder="Phone"
              />
            </div>
            <textarea
              className="input"
              rows="2"
              placeholder="Reason (Optional)"
            />
          </div>
          <div className="footer">
            <button className="submit-btn" onClick={submit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactBox;

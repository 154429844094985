import React from "react";
import "./style.css";


function GridBox(){
    return(
        <div className="grid grid-cols-3 mt-5 gap-3 mx-4">
            <div className="grid-bg">
                <p className="grid-text-one">120</p>
                <p className="grid-text-two">Total Hb tests</p>
            </div>
            <div className="grid-bg">
                <p className="grid-text-one">225</p>
                <p className="grid-text-two">Patients Added</p>
            </div>
            <div className="grid-bg">
                <p className="grid-text-one">80</p>
                <p className="grid-text-two">Anemia Detected</p>
            </div>
        </div>
    )
}

export default GridBox;
import React, { useState } from "react";
import cross from "../../assets/Images/cross.png";
import "./style.css";

function AddUserBox(props) {
  const { open, close } = props;

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      {open && (
        <div className="outer-box">
          <div className="box-two">
            <div className="heading-box mt-4">
              <p className="title">Add user</p>
              <div className="p-1" onClick={close}>
                <img src={cross} alt="cross-icon" height="20" width="20" />
              </div>
            </div>

            <div className="mt-8">
              <input placeholder="Name" className="input w-full" />
              <div className="heading-box mt-5">
                <input placeholder="Username" className="input w-[22rem]" />
                <input placeholder="User ID" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-5">
                <input placeholder="Email" className="input w-[22rem]" />
                <input placeholder="Phone" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-5">
                <input placeholder="Role" className="input w-[22rem]" />
                <input placeholder="Account Type" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-5">
                <input placeholder="Address" className="input w-[22rem]" />
                <input placeholder="State" className="input w-[22rem]" />
              </div>
              <div className="heading-box mt-5">
                <input
                  placeholder="Allocated Users"
                  className="input w-[22rem]"
                />
                <input
                  placeholder="Allocated Tests"
                  className="input w-[22rem]"
                />
              </div>
            </div>

            <div className="flex mt-8 mb-12">
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                  className=""
                />
                <span className="ml-5 text-sm font-pop400 text-darkGrey">Notify user for email</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                  className="ml-[12rem]"
                />
                <span className="ml-5 text-sm font-pop400 text-darkGrey">Notify user for messages</span>
              </label>
            </div>

            <div className="heading-box mt-5">
              <button className="footer-btn">Cancel</button>
              <button className="footer-btn">Add User</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddUserBox;

import React from "react";
import AdminCardLayout from "../../Packages/AdminCardLayout";

function AdminPriceCard() {
  return (
    <div>
      <div className="flex items-center h-auto my-5" >
        {/* <div className="flex flex-col items-center"> */}
          <AdminCardLayout
            type={"Basic"}
            price={"Free"}
            validity={"Active - 7 days"}
          />
        {/* </div> */}
      </div>
    </div>
  );
}

export default AdminPriceCard;

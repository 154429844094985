import React from "react";
import ThreeDotMenu from "../ThreeDotMenu";

const users = [
  {
    id: 1,
    name: "Afreena Yeasmin",
    type: "Admin",
  },
  {
    id: 2,
    name: "Piyush Pal",
    type: "Admin",
  },
  {
    id: 3,
    name: "Rahul Mondal",
    type: "Super User",
  },
  {
    id: 4,
    name: "Tina Dutta",
    type: "Admin",
  },
  {
    id: 5,
    name: "Karan Singh",
    type: "Admin",
  },
];

function UserList() {
  return (
    <div className="mt-4">
      <div className="mx-4 px-6 pb-4">
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-2xl font-pop600 text-darkteal">Recent User</div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <div>Search Box</div>
          </div>
          <div className="flex-1 flex justify-end items-center">
            <div>Filter By</div>
          </div>
        </div>
      </div>

      <div className="mx-4">
        {users.map((user) => (
          <div key={user.id} className="flex flex-row justify-between items-center w-full h-[3.75rem] border border-lightGrey rounded-xl p-4 mb-2">
            <div className="flex flex-col">
              <div className="text-sm font-pop500 text-darkTeal">{user.name}</div>
              <div className="text-xs text-darkGrey font-pop400">{user.type}</div>
            </div>
            <div>
             <ThreeDotMenu/> 
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserList;

import React, { useState } from "react";
import Modal from "../PricingModal";
import AdminPriceCard from "../AdminPriceCard";
import Data from "../../SampleDateThree.json";
import edit from "../../assets/Images/Edit.png";
import "./style.css";

function PricingPlan() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="mt-8">
      <div className="first mx-5">
        <p className="title">Pricing Plan</p>
        <button className="add-distributor-btn" onClick={openModal}>
          Add Plan
        </button>
      </div>

      <hr class="border-t border-lightGrey mt-10" />

      <div className="mx-5">
        <div className="grid-body">
          <div className="grid-item col-span-2">
            <p className="text-2xl font-pop600">Plan List</p>
          </div>
          <div className="grid-item col-span-3 justify-end">
            <input
              placeholder="Search by name, email, etc"
              // onChange={(e) => setSearch(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="grid-item col-span-2 justify-end">
            <p className="filter-text">Sort By</p>
            <input placeholder="Country" className="sort-input" />
            <input placeholder="Status" className="sort-input" />
            <input placeholder="Date" className="sort-input" />
          </div>
        </div>

        <div className="grid grid-cols-6 my-3">
          <p className="list-title text-left ml-5">Name</p>
          <p className="list-title">Price</p>
          <p className="list-title">Country</p>
          <p className="list-title">Start Date</p>
          <p className="list-title">End Date</p>
        </div>

        <ul className="t-body">
          {Data.map((data, i) => (
            <li className="table-list">
              <p className="list-value text-left ml-5">{data.type}</p>
              <p className="list-value">{data.price}</p>
              <p className="list-value">{data.country}</p>
              <p className="list-value">{data.start}</p>
              <p className="list-value">{data.end}</p>
              <button className="bg-darkTeal text-sm font-pop500 text-white rounded w-[9rem] h-[2rem]">Deactivate Plan</button>
            </li>
          ))}
        </ul>
      </div>

      <Modal openModal={isOpen} closeModal={closeModal} />
    </div>
  );
}

export default PricingPlan;

import React, { useState } from "react";
import up from "../../assets/Images/up-arrow.png";
import down from "../../assets/Images/down-arrow.png";
import "./style.css";

function Accordion(props) {
  const {title} = props;
  const [open, setOpen] = useState(false);
  return (
    <div className="accordion-body">
      <button onClick={() => setOpen(!open)} className="accordion">
        <span className="accordion-title">{title}</span>
        {open ? (
          <img src={up} alt="up" height="15" width="15" />
        ) : (
          <img src={down} alt="down" height="15" width="15" />
        )}
      </button>

      {open && (
        <div>
          <div className="accordion-item">
            <div className="accordion-text">Paid Until</div>
            <div className="accordion-text">13 Nov 2024</div>
          </div>
          <div className="accordion-item">
            <div className="accordion-text">Invoice Contact</div>
            <div className="accordion-text">abcd@gmail.com</div>
          </div>
          <div className="accordion-item">
            <div className="accordion-text">Payment Details</div>
            <div className="accordion-text">Outbound Link</div>
          </div>
          <div className="accordion-item">
            <div className="accordion-text">Payment Status</div>
            <div className="accordion-text">Done</div>
          </div>
          <div className="accordion-item">
            <div className="accordion-text">Attachment</div>
            <div className="accordion-text">View</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Accordion;

const url = "https://4ydrl7u5vd.execute-api.us-east-1.amazonaws.com/test/customers";

export async function checkUser(email) {
  const result = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "2ecEueyZnp7SGWgYtgJRj4Du05gKO4wg3JBxlxZg",
    },
    body: JSON.stringify({
      operation: "check_user",
      payload: {
        email,
      },
    }),
  });
  const data = await result.json();
  return data;
}

export async function removeUser(email) {
  const response = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "2ecEueyZnp7SGWgYtgJRj4Du05gKO4wg3JBxlxZg",
    },
    body: JSON.stringify({
      operation: "remove_user",
      payload: {
        email,
      },
    }),
  });
  const resdata = await response.json();
  return resdata;
}

import React from "react";

function Timeline() {
  const [hbLevel, setHbLevel] = React.useState(10);
  return (
    <div className="flex flex-col items-center m-2.5">
      <div
        className="relative overflow-hidden bg-gradient-to-r from-red via-timelineGradTwo to-timelineGradThree h-5 w-full rounded-sm flex flex-row"
      >
        {/* Bar */}
        <div className="flex flex-1 flex-row relative">
          {/* Indicator */}
          <div
            className="absolute w-5 h-5 rounded-xl bg-white"
            style={{
              left:
                hbLevel > 7
                  ? `${((hbLevel - 5) / 15) * 100}%`
                  : `${((hbLevel - 5) / 15) * 100}%`,
            }}
          />
        </div>

        {/* Tick marks */}
        <div className="absolute flex flex-row justify-between px-5 -top-1 left-0 right-0">
          {[...Array(16).keys()].map((i) => (
            <div key={i} className="w-0.5 h-2.5 bg-white" />
          ))}
        </div>
      </div>

      <div className="mt-1.5 flex flex-row justify-between w-full px-5">
        {[...Array(16).keys()].map((i) => (
          <div key={i} className="text-black text-xs w-0.5 font-pop500">
            {i + 5}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;

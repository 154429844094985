import React from "react";
import { Route, Routes } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import ContactSales from "./pages/ContactSales";
import DownloadLink from "./pages/DownloadLink";
import NewUnsubscribe from "./pages/NewUnsubscribe";
import Unsubscribe from "./pages/Unsubscribe";
import NewPlan from "./pages/NewPlan";
import ValidateEmail from "./pages/ValidateEmail";
import Sales from "./pages/Sales";
import Login from "./pages/Login";
// import Login from "./Packages/Login";
import Dashboard from "./pages/Dashboard";
import Footer from "./Packages/Footer";
import "../src/index.css";
import ModalBox from "./Packages/ModalBox";

const theme = createTheme({
  palette: {
    primary: {
      // main: "#24A789",
      main: "#1C5988",
    },
    secondary: {
      main: "#FFFF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          width: 290,
          height: 51,
          borderRadius: 10,
          backgroundColor: "#153B50",
          textTransform: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline></CssBaseline>
      <Routes>
        <Route path="/" element={<NewPlan />} />
        <Route path="/ValidateEmail" element={<ValidateEmail />} />
        <Route path="/ContactSales" element={<ContactSales />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/login" element={<Login />} />
        <Route path="/download" element={<DownloadLink />} />
        <Route path="/unsubscribe" element={<NewUnsubscribe />} />
        {/* <Route path="/Unsubscribe" element={<Unsubscribe />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/modal" element={<ModalBox />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import View from "@mui/icons-material/RemoveRedEyeOutlined";
import Edit from "@mui/icons-material/EditOutlined";
import Email from "@mui/icons-material/EmailOutlined";
import Trends from "@mui/icons-material/TimelineOutlined";
import Delete from "@mui/icons-material/DeleteOutlineOutlined";
import DrawerBox from "../DrawerBox/index";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const users = [
  {
    id: 1,
    name: "Afreena Yeasmin",
    type: "Admin",
  },
  {
    id: 2,
    name: "Piyush Pal",
    type: "Admin",
  },
  {
    id: 3,
    name: "Rahul Mondal",
    type: "Super User",
  },
  {
    id: 4,
    name: "Tina Dutta",
    type: "Admin",
  },
  {
    id: 5,
    name: "Karan Singh",
    type: "Admin",
  },
];

const icon = {
  height: 25,
  width: 25,
  marginRight: 2,
  color: "#09375A",
};
const item = {
  color: "#09375A",
  fontSize: 14,
  fontFamily: "Poppins-Medium",
};

function ThreeDotMenu(props) {
//   const { viewFun, editFun, mailFun, trendFun } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [trend, setTrend] = React.useState(false);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = () => {
    handleClose();
    setView(true);
    setEdit(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  };

  const handleEditClick = () => {
    handleClose();
    setEdit(true);
    setView(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  };
  const handleMailClick = () => {
    handleClose();
    setMail(true);
    setView(false);
    setEdit(false);
    setTrend(false);
    setDrawerOpen(true);
  };

  const handleTrendClick = () => {
    handleClose();
    setTrend(true);
    setView(false);
    setEdit(false);
    setMail(false);
    setDrawerOpen(true);
  };

  function toggleDrawer(){
    setDrawerOpen(false);
  }

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: "#09375A" }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleViewClick} sx={item}>
          <View sx={icon} /> View
        </MenuItem>
        <MenuItem onClick={handleEditClick} sx={item}>
          <Edit sx={icon} /> Edit
        </MenuItem>
        <MenuItem onClick={handleMailClick} sx={item}>
          <Email sx={icon} /> Email
        </MenuItem>
        <MenuItem onClick={handleTrendClick} sx={item}>
          <Trends sx={icon} /> Trends
        </MenuItem>
        <MenuItem sx={item}>
          <Delete sx={icon} /> Delete
        </MenuItem>
      </Menu>
      <DrawerBox anchor="right" open={drawerOpen} close={toggleDrawer} view={view} edit={edit} mail={mail} trend={trend} />
    </div>
  );
}

export default ThreeDotMenu;

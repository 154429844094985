import React from "react";
import back from "../../assets/Images/back.png";
import next from "../../assets/Images/next.png";
import "../../Packages/PatientsMenu/style.css";
import StartHbBox from "../StartHbBox";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import AddPatientsBox from "../AddPatientBox";
import AddUserBox from "../AddUserBox";

const users = [
  {
    id: 1,
    name: "Afreena Yeasmin",
    type: "Admin",
  },
  {
    id: 2,
    name: "Piyush Pal",
    type: "Admin",
  },
  {
    id: 3,
    name: "Rahul Mondal",
    type: "Super User",
  },
  {
    id: 4,
    name: "Tina Dutta",
    type: "Admin",
  },
  {
    id: 5,
    name: "Karan Singh",
    type: "Admin",
  },
];

function UsersMenu() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [trend, setTrend] = React.useState(false);
  const [addPatient, setAddPatient] = React.useState(false);
  const [hbtest, setHbtest] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 4;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = users.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(users.length / recordsPerPage);

  function backPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function nextPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }
  function addPatientFun() {
    setAddPatient(!addPatient);
    setDrawerOpen(!drawerOpen);
  }

  // const [search, setSearch] = React.useState('');
  // console.log(search);

  function viewFun() {
    setView(true);
    setEdit(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function editFun() {
    setEdit(true);
    setView(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function mailFun() {
    setMail(true);
    setView(false);
    setEdit(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function trendFun() {
    setTrend(true);
    setView(false);
    setEdit(false);
    setMail(false);
    setDrawerOpen(true);
  }

  function startHb() {
    setHbtest(!hbtest);
  }
  function toggleDrawer() {
    setDrawerOpen(false);
  }
  return (
    <div className="mt-8">
      <div className="grid grid-cols-8 gap-2">
        <div className="grid-item">
          <p className="text-2xl font-pop600">Users</p>
        </div>
        <div className="grid-item col-span-2">
          <input
            placeholder="Search user"
            size="small"
            style={{  paddingLeft: 10 }}
            // onChange={(e) => setSearch(e.target.value)}
            className="border border-lightGrey p-2 rounded-xl text-midGrey font-pop500 text-sm w-[16rem]"
          />
        </div>
        <div className="grid-item col-span-2">
          <p className="filter-text">Filter By</p>
          <input
            placeholder="Role"
            className="sort-input"
          />
          <input placeholder="Division" className="sort-input" />
          <input placeholder="Date" className="sort-input" />
        </div>
        <div className="grid-item col-span-3 justify-end mr-10">
          <button className="add-patient-btn" onClick={addPatientFun}>Add User</button>
          <AddUserBox 
            open={drawerOpen}
            close={toggleDrawer}
          />
        </div>
      </div>

      <hr class="border-t border-lightGrey mt-10"/>

      <div className="mx-4 mt-5">
        {users.map((user) => (
          <div key={user.id} className="flex flex-row justify-between items-center w-full h-[3.75rem] border border-lightGrey rounded-xl p-4 mb-2">
            <div className="flex flex-col">
              <div className="text-sm font-pop500 text-darkTeal">{user.name}</div>
              <div className="text-xs text-darkGrey font-pop400">{user.type}</div>
            </div>
            <div>
             <ThreeDotMenu/> 
            </div>
          </div>
        ))}
      </div>
     
    </div>
  );
}

export default UsersMenu;

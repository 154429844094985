import React from "react";
import back from "../../assets/Images/back.png";
import next from "../../assets/Images/next.png";
import users from "../../SampleData";
import "../../Packages/PatientsMenu/style.css";
import StartHbBox from "../StartHbBox";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import AddPatientsBox from "../AddPatientBox";

function PatientsMenu() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [trend, setTrend] = React.useState(false);
  const [addPatient, setAddPatient] = React.useState(false);
  const [hbtest, setHbtest] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 4;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = users.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(users.length / recordsPerPage);

  function backPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function nextPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }
  function addPatientFun() {
    setAddPatient(!addPatient);
    setDrawerOpen(!drawerOpen);
  }

  // const [search, setSearch] = React.useState('');
  // console.log(search);

  function viewFun() {
    setView(true);
    setEdit(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function editFun() {
    setEdit(true);
    setView(false);
    setMail(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function mailFun() {
    setMail(true);
    setView(false);
    setEdit(false);
    setTrend(false);
    setDrawerOpen(true);
  }
  function trendFun() {
    setTrend(true);
    setView(false);
    setEdit(false);
    setMail(false);
    setDrawerOpen(true);
  }

  function startHb() {
    setHbtest(!hbtest);
  }
  function toggleDrawer() {
    setDrawerOpen(false);
  }
  return (
    <div className="mt-8">
      <div className="grid grid-cols-8 gap-2">
        <div className="grid-item">
          <p className="text-2xl font-pop600">Patients</p>
        </div>
        <div className="grid-item col-span-2">
          <input
            placeholder="Search patients"
            size="small"
            style={{  paddingLeft: 10 }}
            // onChange={(e) => setSearch(e.target.value)}
            className="bg-lightGrey p-2 rounded-xl text-midGrey font-pop500 text-sm w-[16rem]"
          />
        </div>
        <div className="grid-item col-span-2">
          <p className="filter-text">Filter By</p>
          <input
            placeholder="Name"
            className="sort-input"
          />
          <input placeholder="Date" className="sort-input" />
          <input placeholder="Age" className="sort-input" />
        </div>
        <div className="grid-item col-span-3 justify-end mr-10">
          <button className="add-patient-btn" onClick={addPatientFun}>Add Patients</button>
          <AddPatientsBox 
            open={drawerOpen}
            close={toggleDrawer}
          />
        </div>
      </div>

      <hr class="border-t border-lightGrey mt-10"/>

      <div className="flex flex-col mx-5 mt-5">
      {records.map((user, index) => (
        <div className="border border-lightGrey px-3 py-2 mb-3 rounded-lg grid grid-cols-10" key={index}>
          <div className="patient-list col-span-3">
            <p className="username">{user.name}</p>
            <p className="usertype">{user.type}</p>
          </div>
          <div className="patient-list col-span-3">
          <p className="usertype">
                Last tested on{" "}
                <span className="usertype font-pop500">Mon, 12 Aug 2023</span>
              </p>
              <p className="username text-xs">
                Hemoglobin{" "}
                <span style={{ color: "#CC2936" }}>
                  Level <span>8.81g/dL</span>
                </span>
              </p>
          </div>
          <div className="patient-list items-end col-span-3">
          <button
                className="start-hb-btn"
                onClick={() => (users[index].id === user.id ? startHb() : null)}
              >
                Start Hb Test
              </button>
              <StartHbBox open={hbtest} close={startHb} />
          </div>
          <div className="patient-list items-center">
          <ThreeDotMenu />
          </div>
        </div>
      ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 100,
        }}
      >
        <button onClick={backPage} className="paginationBtn">
          <img src={back} alt="back" />
        </button>
        <span
          className={{
            fontSize: 12,
            fontFamily: "Poppins-Medium",
            color: "#153B50",
            paddingTop: 5,
          }}
        >
          Your browsing page{" "}
          <span
            style={{
              border: "0.5px solid #8B979E",
              padding: "5px 10px",
              borderRadius: 4,
              margin: "0px 5px",
            }}
          >
            {currentPage}
          </span>{" "}
          of {totalPages}
        </span>
        <button onClick={nextPage} className="paginationBtn">
          <img src={next} alt="back" />
        </button>
      </div>
    </div>
  );
}

export default PatientsMenu;

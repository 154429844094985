import React from "react";
import Header from "../../Packages/Header";
import Footer from "../../Packages/Footer";
// import ModalBox from "../Packages/ModalBox";
import bg2 from "../../assets/Images/bg2.png";
import tick from "../../assets/Images/tick.png";
import appstore from "../../assets/Images/app-store.png";
import playstore from "../../assets/Images/play-store.png";

const div = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function gotoPlaystore() {
  window.open(
    "https://play.google.com/store/apps/details?id=ai.monere.niada&pcampaignid=web_share",
    "_self"
  );
}
function gotoAppstore() {
  window.open("https://testflight.apple.com/join/o8y79AEV", "_self");
}

const DownloadLink = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          width: "100%",
          height: "233px",
          marginBottom: "50px",
        }}
      >
        <Header />
        <p className="headingOne">Download NiADA</p>
      </div>
      <div style={div}>
        <div className="myBox">
          <div className="flex flex-col justify-center items-center">
            <div className="flex justify-center mb-4">
              <img src={tick} alt="logo" />
            </div>

            <p className="text-2xl font-pop600 text-darkTeal mb-4">
              Successfully Logged In
            </p>
            <p className="text-base font-pop500 text-darkGrey mb-10">
              You have successfully logged into our website. You can now
              download NiADA App from Google Playstore & Apple Appstore.
            </p>

            <div className="flex flex-row items-center justify-between">
              <a
                href="https://play.google.com/store/apps/details?id=ai.monere.niada&pcampaignid=web_share"
                className="mx-5"
              >
                <img src={playstore} alt="logo" width="200" height="50" />
              </a>
              <a
                href="https://testflight.apple.com/join/o8y79AEV"
                className="mx-5"
              >
                <img src={appstore} alt="logo" width="200" height="50" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DownloadLink;

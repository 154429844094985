import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import CardLayout from "../../components/CardLayout";
import "../ValidateEmail/style.css";
import Header from "../../Packages/Header";
import Footer from "../../Packages/Footer";
import bg2 from "../../assets/Images/bg2.png";
import "../../style/Typography.css";
import PurchaseModal from "../../components/PurchaseModal";
// import CustomModal from "../../components/CustomModal";
import ModalBox from "../../Packages/ModalBox";

const steps = [
  {
    id: 1,
    name: "Validate Account",
  },
  {
    id: 2,
    name: "Package Details",
  },
  {
    id: 3,
    name: "Payment",
  },
  {
    id: 4,
    name: "Complete",
  },
];

const gridBody = {
  width: "95%",
  display: "flex",
  // flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  // backgroundColor: "red",
  // paddingLeft: 10,
  // paddingRight: 10,
  marginBottom: 70,
  // marginRight: 10,
  // marginLeft: 10
};

const item = {
  // backgroundColor: "pink",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  // border: '1px solid black',
  padding: 20,
  // marginRight: 15,
  // marginLeft: 15,
  width: 200,
};
const stepTxt1 = {
  backgroundColor: "#153B50",
  color: "white",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 50,
  width: 60,
  height: 60,
  marginRight: 20,
  fontSize: 32,
  fontFamily: "Poppins-SemiBold",
};
const stepTxt2 = {
  // backgroundColor: "green",
  color: "#153B50",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 24,
  fontFamily: "Poppins-SemiBold",
};
const div = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function Plan() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Header />
      <div
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          width: "100%",
          height: "233px",
          marginBottom: "50px",
        }}
      >
        <Typography className="headingOne">Buy NiADA</Typography>
      </div>

      <div style={div}>
        <Grid container style={gridBody}>
          {steps.map((step) => (
            <Grid item xs={12} sm={12} md={6} lg={3} key={step.id} style={item}>
              <Typography style={stepTxt1}>{step.id}</Typography>
              <Typography style={stepTxt2}>{step.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={div}>
        <ModalBox
          headingName={"First we need to validate your email"}
          headingPresent={true}
          purchase={true}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Plan;

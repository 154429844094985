const url = "https://admin-dev.app.monere.ai/service";

async function fetchPlan() {
  const result = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-API-KEY": "deTMyjrrZfwV0FeuavN54nuLJjBEIwx2abNDf7d2",
    },
    body: JSON.stringify({
      resource: "payment",
      operation: "fetch_pricing_plans",
      payload: { country: "235" },
    }),
  });
  const data = await result.json();
  return data;
}

export default fetchPlan;
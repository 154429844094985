import React, { useEffect, useState } from "react";
import { createUser, verifyUser } from "../../components/Scripts/Api";
import "../../Packages/ModalBox/style.css";
import PlanTypeMenu from "../../Packages/PlanTypeMenu";
import tick from "../../assets/Images/tick.png";
import { useNavigate } from "react-router-dom";

const plans = [
  {
    name: "Basic",
    price: "₹399",
  },
  {
    name: "Group / Family",
    price: "₹1450",
  },
];


function ModalBox(props) {
  const { headingName, headingPresent, purchase } = props;

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCode, setIsValidCode] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [getcode, setGetcode] = useState("");

  const [isEmail, setIsEmail] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const [userdata, setUserdata] = useState(true);

  useEffect(() => {
    const clearOtpAfterTimeout = () => {
      setCode("");
    };
    setTimeout(clearOtpAfterTimeout, 600000);
  }, [code]);

  const navigate = useNavigate();

  const handleClick = async () => {
    if (isEmail) {
      if (email.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty");
        setIsValidEmail(false);
      } else {
        // console.warn(email);
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const isValid = regex.test(email);
        setIsValidEmail(isValid);
        if (!isValid) {
          setErrorMessage("Please enter a valid email address.");
        } else {
          setIsEmail(false);
          setIsOtp(true);
          const data = await createUser(email);
          // console.log(data);
          // console.log(data.code);
          const resCode = data.code;
          setCode(resCode);
          // console.log(data.user_data);
          const resdata = data.user_data;

          if (resdata.length === 0) {
            setUserdata(false);
          }
        }
      }
    } else {
      // console.warn(code);

      if (getcode.trim() === "") {
        setErrorMessage("This field is required!");
        // console.log("empty code box");
        setIsValidCode(false);
      } else {
        if (code !== getcode) {
          setErrorMessage(
            "This verification code is incorrect. Please try again!"
          );
          setIsValidCode(false);
        } else {
          if (userdata === false) {
            const resdata = await verifyUser(email, "");
            // console.log(resdata);
            // console.log(resdata.statusCode);
            const stscode = resdata.statusCode;
            if (stscode === 200) {
              setIsOtp(false);
              setIsLogin(true);
            } else {
              alert("Opps something went wrong!");
            }
          } else {
            setIsOtp(false);
            setIsLogin(true);
            navigate("/download");
          }
        }
      }
    }
  };

  // const logged = () => {
  //   handleClick();
  //   setIsOtp(false);
  //   setIsLogin(true);
    
  // };

  // function gotoHomepage(){
  //  window.open("https://www.monere.ai/", "_self");
  // }

  return (
    // Modal Box
    <div className="myBox">
      {isEmail && (
        <div>
          {headingPresent && (
            <div className="header">
              <p className="heading">{headingName}</p>
            </div>
          )}
          <div className="header">
            <p className="lable">Email Address:</p>
            <input
              required
              placeholder="Enter your email address"
              onChange={(i) => setEmail(i.target.value)}
              className="inputBox"
            />
            {!isValidEmail && (
              <small className="errorMsg">{errorMessage}</small>
            )}
          </div>
        </div>
      )}

      {isOtp && (
        <div>
          {headingPresent && (
            <div className="header">
              <p className="heading">{headingName}</p>
            </div>
          )}
          <div className="body">
            <p className="lable">Email Address</p>
            <p className="lable text-primeBlue">{email}</p>
            <p className="lable mt-[3.125rem]">
              Enter the Verification code sent to your email:
            </p>
            <input
              required
              placeholder="Verification code"
              onChange={(v) => setGetcode(v.target.value)}
              // helperText={!isValidCode ? errorMessage : ""}
              // error={!isValidCode}
              className="inputBox"
            />
            {!isValidCode && <small className="errorMsg">{errorMessage}</small>}
          </div>
        </div>
      )}

      {/* {isLogin && (
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center mb-4">
            <img src={tick} alt="logo" />
          </div>

          <p className="text-2xl font-pop600 text-darkTeal mb-4">
            Successfully Logged In
          </p>
          <p className="text-base font-pop500 text-darkGrey mb-4">
            You have successfully logged in into our website. You can now
            download NiADA App from Google Playstore & Apple Appstore.
          </p>
          <a
            href="https://testflight.apple.com/join/o8y79AEV"
            className="text-base font-pop500 text-primeGreen mb-2"
          >
            Download from Appstore
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ai.monere.niada&pcampaignid=web_share"
            className="text-base font-pop500 text-primeGreen mb-6"
          >
            Download from Playstore
          </a>

          <button className="w-[6.5rem] h-[3rem] rounded-xl bg-darkTeal text-base font-pop600 text-white" onClick={gotoHomepage}>
            Continue
          </button>
        </div>
      )} */}

      {isEmailValidated && (
        <div>
          <div className="header">
            <p className="heading">Confirm package details:</p>
            <p className="heading text-center text-primeBlue mt-5">
              {" "}
              You are about to purchase:
            </p>
          </div>
          <div className="body">
            <PlanTypeMenu plans={plans} />
            <hr />
          </div>
        </div>
      )}

      {isEmail && (
        <div className="flex items-center self-center">
          <button onClick={handleClick} className="btn">
            Continue
          </button>
        </div>
      )}

      {isOtp && (
        <div className="flex items-center self-center">
          <button onClick={handleClick} className="btn">
            Verify
          </button>
        </div>
      )}

      {isEmailValidated && (
        <div className="priceBox">
          <div className="flex">
            <p className="priceTxt">
              You have to pay<p className="price">₹399</p>
            </p>
          </div>
          <div className="flex">
            <button onClick={handleClick} className="btn">
              Buy Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalBox;

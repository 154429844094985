import React from "react";
import { Typography, Box, Avatar, CardContent, Button } from "@mui/material";
import { styled } from "@mui/system";
import "./style.css";

const CustCard = styled("Card")({
  backgroundColor: "#E6EBEE",
  //   position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 350,
  minWidth: 350,
  height: 487,
  borderRadius: 10,
  margin: 20,
  transition: "0.2s ease-out",
  "&:hover": {
    borderColor: "#008bf8",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.25)",
    transform: "scale(1.02)",
  },
});

const PricingCard = (props) => {
  const {
    recommended,
    planName,
    icon,
    list,
    price,
    limit,
    primaryBtn,
    primaryBtnFun,
    secondaryBtn,
    secondaryBtnFun,
    extrabtn,
  } = props;
  return (
    <CustCard>
      <Box className="groupOne">
        <Typography gutterBottom variant="h5" component="div" className="title">
          {planName}
        </Typography>
        <Typography component="div" className="price-box">
          {price}
        </Typography>
        <Typography className="limit" sx={{ color: "#034C8C" }}>
          {limit}
        </Typography>
      </Box>
      <Box className="groupTwo">
        <Typography component="div" className="includes">
          Includes:
        </Typography>
        <Typography
          color="text.secondary"
          className="info-txt"
          sx={{ color: "#034C8C" }}
        >
          {list?.map((value) => (
            <Box className="info-txt-box">{value}</Box>
          ))}
        </Typography>
      </Box>

      {extrabtn ? (
        <Box component="div" className="secondary-button">
          <Button variant="contained" onClick={secondaryBtnFun}>
            <Typography className="btnTxt">{secondaryBtn}</Typography>
          </Button>
        </Box>
      ) : (
        <Box component="div" className="primary-button">
          <Button variant="contained" onClick={primaryBtnFun}>
            <Typography className="btnTxt">{primaryBtn}</Typography>
          </Button>
        </Box>
      )}
    </CustCard>
  );
};
export default PricingCard;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
// import { createUser, verifyUser } from "../Scripts/Api";
// import WarningIcon from "@mui/icons-material/Warning";
// import DoneIcon from "@mui/icons-material/Done";
// import PlanTypeMenu from "../PlanTypeMenu";
import NewHeader from "../../components/NewHeader";
import NewFooter from "../../components/NewFooter";
import ContactBox from "../../Packages/ContactBox";
import bg2 from "../../assets/Images/bg2.png";

const div = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const Sales = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          width: "100%",
          height: "233px",
          marginBottom: "50px",
        }}
      >
        <NewHeader />
        <Typography className="headingOne">Contact Us</Typography>
      </div>
      <div style={div}>
        <ContactBox />
      </div>
      <NewFooter />
    </div>
  );
};

export default Sales;

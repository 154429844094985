import React from "react";
/*import UserList from "../UserList";*/
import AccordionSidebar from "../AccordionSidebar";
import GridBox from "../GridBox";
import AddUserBox from "../AddUserBox";
import "./style.css";

function SubscriptionMenu() {
  return (
    <div className="mt-8 mx-5">
      <div className="first">
        <p className="title">Subscription</p>
      </div>

      <hr class="border-t border-lightGrey mt-10" />

      <div className="flex mt-3">
        <div className="status-box mr-3">
          <div>
            <p className="status-title">Quota Assigned</p>
            <p className="status-value">110</p>
          </div>
          <div className="ml-8">
            <p className="status-title">Autorenewal</p>
            <p className="status-value">True</p>
          </div>
        </div>
        <div className="status-box">
          <div>
            <p className="status-title">Quota Bought</p>
            <p className="status-value">120</p>
            <button className="button w-[10rem]">Buy More Tests</button>
          </div>
          <div className="ml-8">
            <p className="status-title">Quota Used</p>
            <p className="status-value">116</p>
          </div>
        </div>
        <div className="status-box ml-3">
          <div>
            <p className="status-title">User Seats</p>
            <p className="status-value">5</p>
            <button className="button w-[10rem]">Buy More Seats</button>
          </div>
          <div className="ml-8">
            <p className="status-title">Tests per Seats</p>
            <p className="status-value">10</p>
          </div>
        </div>
      </div>

      <div className="bg-base rounded-xl mt-5 px-5 h-[21rem] flex flex-col justify-center">
        <p className="mb-8 text-primeBlue text-2xl font-pop600">
          Account Details
        </p>
        <div className="flex">
          <div className="w-[19rem]">
            <p className="acc-title">Customer Name</p>
            <p className="acc-value">Sanjai Guha</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Account Status</p>
            <p className="text-lg font-pop600 mt-2 text-primeGreen">Active</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Admin Email</p>
            <p className="acc-value">sanjay.guha@gmail.com</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Admin Phone</p>
            <p className="acc-value">+91-9876543210</p>
          </div>
        </div>
        <div className="flex mt-10">
          <div className="w-[19rem]">
            <p className="acc-title">Role</p>
            <p className="acc-value">Super Admin</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Date Created</p>
            <p className="acc-value">22 Oct 2019</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Account Start</p>
            <p className="acc-value">02 Dec 2019</p>
          </div>
          <div className="w-[19rem]">
            <p className="acc-title">Account Type</p>
            <p className="acc-value">Lorem Ipsum</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionMenu;

import React, {useState} from "react";


function PlanTypeMenu(props){
    const { plans } = props;

    const [selectedPlan, setSelectedPlan] = useState("Basic");
    function handleChange(event){
        setSelectedPlan(event.target.value);
    }
    return(
        <div>
           <select value={selectedPlan} onChange={handleChange} className="w-[32.5rem] h-[3.75rem] bg-gradient-to-b from-greenOne to-greenTwo rounded-xl mb-6 font-pop600 text-xl text-darkGrey pl-5">
             {plans.map((plan, index) => (
                <option key={index} value={plan.name}>{plan.name}</option>
             ))}
           </select>
           {/* <div>
            <select className="w-[15.31rem] h-[3.125rem]">
                <option></option>
            </select>
            <select>
                <option></option>
            </select>
           </div> */}
        </div>
    )
}

export default PlanTypeMenu;
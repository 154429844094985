import React from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/system";

const SubLinks = styled("Typography")({
    fontSize: 18,
    // marginBottom: 8,
  });
  
  const LinkHeader = styled("Typography")({
    fontSize: 24,
    marginBottom: 30,
    fontWeight: "bold",
  });

function Footer() {
  return (
    <Box>
      <Box>
        <Grid
          container
          sx={{
            background: "linear-gradient(to right, #2295C5, #24A984)",
            height: "auto",
            color: "#FFFF",
            // pt: 10,
          }}
        >
          <Grid item xs={12} sm={6} md={3} lg={3} display="flex" flexDirection="column" sx={{padding:5}}>
          <img
                src="assets/future-data.png"
                alt=""
                height="80"
                width="80"
                style={{ marginBottom: 50 }}
              />
               <SubLinks>
                Driving Efficiency in Public
                <br />
                Health With Artificial
                <br />
                Intelligence
              </SubLinks>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} display="flex" flexDirection="column" sx={{padding:5}}>
          <LinkHeader>QUICK LINKS</LinkHeader>
              <SubLinks>Home</SubLinks>
              <SubLinks>What We Are Solving</SubLinks>
              <SubLinks>Our Solution</SubLinks>
              <SubLinks>Partner with Us</SubLinks>
              <SubLinks>Who We Are</SubLinks>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} display="flex" flexDirection="column" sx={{padding:5}}>
          <LinkHeader>GET IN TOUCH</LinkHeader>
              <SubLinks>Tel: +1 408-464-2253</SubLinks>
              <SubLinks sx={{ mb: 6 }}>Email: support@monere.ai</SubLinks>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} display="flex" flexDirection="column" sx={{padding:5}}>
          <LinkHeader>ADDRESS</LinkHeader>
              <SubLinks>
                4121 N Morning View Way.
                <br />
                Lehi, UT, USA,
                <br />
                84043
              </SubLinks>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#1E8C76", height: 50 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography sx={{ color: "white"}}>Copyright © 2023 Future Data. All Rights Reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Footer;

import * as React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
// import Sidebar from "../../components/Sidebar";
import Sidebar from "../../Packages/Sidebar";
// import DashboardMenu from "../../components/DashboardMenu";
import DashboardMenu from "../../Packages/DashboardMenu";
import UsersMenu from "../../Packages/UsersMenu";
// import SubscriptionMenu from "../../components/SubscriptionMenu";
import SubscriptionMenu from "../../Packages/SubscriptionMenu";
// import PatientsMenu from "../../components/PatientsMenu";
import PatientsMenu from "../../Packages/PatientsMenu";
import DistributorMenu from "../../Packages/DistributorMenu";
import CustomerMenu from "../../Packages/CustomerMenu";
import PricingPlan from "../../Packages/PricingPlan";

function Dashboard() {
  const [isDash, setIsDash] = React.useState(false);
  const [isUser, setIsUser] = React.useState(false);
  const [isSubs, setIsSubs] = React.useState(false);
  const [isPatients, setIsPatients] = React.useState(false);
  const [isDistributor, setIsDistributor] = React.useState(false);
  const [isCustomer, setIsCustomer] = React.useState(false);
  const [pricingPlan, setPricingPlan] = React.useState(false);
  const [superUser, setSuperUser] = React.useState(false);
  const [admin, setAdmin] = React.useState(true);
  const [superAdmin, setSuperAdmin] = React.useState(false);
  // const [isTrend, setIsTrend] = React.useState(false);
  // const [isLogout, setIsLogout] = React.useState(false);
  return (
    <div style={{ height: 710 }}>
      <Box>
        <Grid container display="flex" flexDirection="row">
          <Grid item lg={2.5} sm={12} xs={12}>
            <Sidebar
              isDash={isDash}
              setIsDash={setIsDash}
              isUser={isUser}
              setIsUser={setIsUser}
              isSubs={isSubs}
              setIsSubs={setIsSubs}
              isPatients={isPatients}
              setIsPatients={setIsPatients}
              isDistributor={isDistributor}
              setIsDistributor={setIsDistributor}
              isCustomer={isCustomer}
              setIsCustomer={setIsCustomer}
              pricingPlan={pricingPlan}
              setPricingPlan={setPricingPlan}
              superUser={superUser}
              setSuperUser={setSuperUser}
              admin={admin}
              setAdmin={setAdmin}
              superAdmin={superAdmin}
              setSuperAdmin={setSuperAdmin}
            />
          </Grid>
          {superUser && (
            <Grid
              item
              lg={9.5}
              sm={12}
              xs={12}
              // sx={{ bgcolor: "lightcoral" }}
            >
              {isDash && <DashboardMenu />}
              {isUser && <UsersMenu />}
              {isSubs && <SubscriptionMenu />}
            </Grid>
          )}
          {admin && (
            <Grid
              item
              lg={9.5}
              sm={12}
              xs={12}
              // sx={{ bgcolor: "lightcoral" }}
            >
              {isPatients && <PatientsMenu />}
            </Grid>
          )}
          {superAdmin && (
            <Grid
              item
              lg={9.5}
              sm={12}
              xs={12}
              // sx={{ bgcolor: "lightcoral" }}
            >
              {isDistributor && <DistributorMenu />}
              {isCustomer && <CustomerMenu />}
              {pricingPlan && <PricingPlan />}
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}

export default Dashboard;

import React from "react";
import Accordion from "../Accordion";

function AccordionSidebar() {
  return (
    <div className="w-[25rem] bg-greyFour mx-3 mt-5 rounded-xl flex flex-col items-center">
      <div className="w-[22rem] h-[10rem] bg-greenThree rounded-xl mt-5 border border-greenFour flex flex-col justify-center items-center mb-1">
        <p className="text-base font-pop600 text-darkGrey">
          Hi, Sunita Agarwal
        </p>
        <p className="text-sm font-pop500 text-darkGrey my-2">
          You have 2/5 test available for April 2024
        </p>
        <button className="w-[20rem] h-[2.5rem] bg-primeGreen rounded-lg text-sm font-pop500 text-white">
          Bye more Tests
        </button>
      </div>

      <div>
        <Accordion title= "Payment Settings"/>
        <Accordion title= "Membership"/>
        <Accordion title= "Account Settings"/>
        <Accordion title= "Notification Settings"/>
      </div>
    </div>
  );
}
export default AccordionSidebar;

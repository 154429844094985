import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function goToHome() {
  window.open("https://www.futuredata.info/", "_blank");
}

function Header() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
    <AppBar sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ marginRight: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          <img
            src="assets/Niada.jpg"
            alt="logo"
            height="50"
            width="200"
            onClick={goToHome}
          />

          <Box sx={{ flexGrow: 0, marginLeft: "auto", display: {xs: "none", sm: "block"} }}>
            <Button
              sx={{
                textDecoration: "none",
                color: "#3EBAFF",
                fontSize: 20,
              }}
              onClick={goToHome}
            >
              Home
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: 200,
            padding: 2,
          }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Button
            sx={{
              textDecoration: "none",
              color: "#3EBAFF",
              fontSize: 20,
              width: "100%",
            }}
            onClick={goToHome}
          >
            Home
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
export default Header;

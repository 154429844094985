import React, { useState } from "react";
import { MenuItem, Select, FormControl } from "@mui/material";
import usa from "../../assets/Images/usa.png";
import india from "../../assets/Images/india.png";

const CountryMenu = () => {
  const [selectedCountry, setSelectedCountry] = useState("india");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  return (
    <FormControl>
      <Select
        value={selectedCountry}
        onChange={handleCountryChange}
        sx={{
          textAlign: "center",
          width: 90,
          height: 40,
          borderRadius: 2,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#1C5988",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#68BD45",
          },
          ".MuiSvgIcon-root ": {
            fill: "#1C5988",
          },
        }}
      >
        <MenuItem value="usa" sx={{ justifyContent: "center" }}>
          <img src={usa} alt="usa" />
        </MenuItem>
        <MenuItem value="india" sx={{ justifyContent: "center" }}>
          <img src={india} alt="india" height="20" width="20" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CountryMenu;

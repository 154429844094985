import React from "react";
/*import UserList from "../../components/UserList";*/
import UserList from "../UserListDir";
import AccordionSidebar from "../AccordionSidebar";
import GridBox from "../GridBox";
import AddUserBox from "../AddUserBox";
import "./style.css";

function DashboardMenu() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [addUser, setAddUser] = React.useState(false);

  function addUserFun() {
    setAddUser(!addUser);
    setDrawerOpen(!drawerOpen);
  }

  function toggleDrawer() {
    setDrawerOpen(false);
  }
  return (
    <div className="mt-8">
      <div className="first mx-5">
        <p className="title">Dashboard</p>
        <button className="add-user-btn" onClick={addUserFun}>
          Add User
        </button>
      </div>
      <AddUserBox open={drawerOpen} close={toggleDrawer} />

      <hr class="border-t border-lightGrey mt-10" />

      <div className="flex flex-row">
        <div>
          <GridBox />
          <UserList />
        </div>
        <AccordionSidebar />
      </div>
    </div>
  );
}

export default DashboardMenu;

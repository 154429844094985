const url = "https://4ydrl7u5vd.execute-api.us-east-1.amazonaws.com/test/customers";

export async function createUser(email) {
  const result = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "2ecEueyZnp7SGWgYtgJRj4Du05gKO4wg3JBxlxZg",
    },
    body: JSON.stringify({
      operation: "create_user",
      payload: {
        email,
        signupCode: "",
        paid_tests: 4,
        paid_users: 1,
        paid_for_time: 1,
        acct_type: "trial",
      },
    }),
  });
  const data = await result.json();
  return data;
}

export async function verifyUser(email, signupCode) {
  const response = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "2ecEueyZnp7SGWgYtgJRj4Du05gKO4wg3JBxlxZg",
    },
    body: JSON.stringify({
      operation: "verify_user",
      payload: {
        email,
        signupCode,
        paid_tests: 4,
        paid_users: 1,
        paid_for_time: 1,
        acct_type: "trial",
      },
    }),
  });
  const resdata = await response.json();
  return resdata;
}

import React, {useState} from 'react';
import "./style.css";
import cross from "../../assets/Images/cross.png";

const PricingModal = ({ openModal, closeModal }) => {
  // Conditional rendering of modal
 
  const [selectedOption, setSelectedOption] = useState("");
  const options = ["Option 1", "Option 2", "Option 3"];

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  if (!openModal) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto bg-blur">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" onClick={closeModal}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        
        {/* Modal content */}
        <div className="modal-body">
          <div className="inner-modal-body">
          <div>
      <div className="first mx-5 mb-8">
        <p className="title">Plan information</p>
        <div className="first">
          <div className="p-1 pl-5" onClick={closeModal}>
            <img src={cross} alt="cross-icon" height="20" width="20" />
          </div>
        </div>
      </div>

      <div className="mx-5 m-3">
        <p className="input-title">Plan Name</p>
        <div className="flex">
          <input className="input" placeholder="Basic" disabled />
          <button className="add-feature-btn">Add Plan Feature +</button>
        </div>
      </div>

      <div className="mx-5 mb-3">
        <div className="flex">
          <p className="input-title">Feature Name</p>
          <p className="input-title ml-[11.5rem]">Feature Count</p>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <select
              value={selectedOption}
              onChange={handleChange}
              className="select-input mb-4"
            >
              {/* <option value="" disabled hidden className="option">
                    Payment Method
                  </option> */}
              {options.map((option, index) => (
                <option key={index} value={option} className="option">
                  {option}
                </option>
              ))}
            </select>
            <select
              value={selectedOption}
              onChange={handleChange}
              className="select-input"
            >
              {options.map((option, index) => (
                <option key={index} value={option} className="option">
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <input className="select-input mb-4" placeholder="eg. 1" />
            <input className="select-input" placeholder="eg. 1" />
          </div>
          <div className="flex flex-col">
          <button className="bg-red text-sm font-pop500 text-white rounded w-[9rem] h-[3.125rem] mb-4">Remove</button>
          <button className="bg-red text-sm font-pop500 text-white rounded w-[9rem] h-[3.125rem]">Remove</button>
          </div>
        </div>
      </div>

      <div className="mx-5 mb-3">
        <p className="input-title">Plan Period</p>
        <div className="first">
          <input
            className="input"
            // placeholder="1 - 30"
            type="date"
          />
          <select
            value={selectedOption}
            onChange={handleChange}
            className="input"
          >
            <option value="" disabled hidden className="option">
              Choose from here
            </option>

            {options.map((option, index) => (
              <option key={index} value={option} className="option">
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mx-5 mb-5">
        <div className="flex">
          <p className="input-title">Active start date</p>
          <p className="input-title ml-[15.5rem]">Active end date</p>
        </div>
        <div className="first">
          <input className="input" type="date" />
          <input className="input" type="date" />
        </div>
      </div>

      <div className="mx-5 mb-5">
        <div className="first">
        <select
            value={selectedOption}
            onChange={handleChange}
            className="input"
          >
            <option value="" disabled hidden className="option">
              Choose Country
            </option>

            {options.map((option, index) => (
              <option key={index} value={option} className="option">
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>


      <div className="mx-5 mb-4 first">
        <button className="modal-btn">Cancel</button>
        <button className="modal-btn">Save</button>
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
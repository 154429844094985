import React, { useEffect, useState } from "react";
import CardLayout from "../../Packages/CardLayout";
import { useNavigate } from "react-router-dom";
import fetchPlan from "../../components/Scripts/FetchPlanApi";

function PricingCard() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [data, setData] = useState(null);
  // const [planData, setPlanData] = useState(null);
  // const [featureData, setFeatureData] = useState(null);
  // const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  function validate() {
    navigate("/ValidateEmail");
  }
  function goToSales() {
    navigate("/Sales");
  }
  // function product2() {
  //   window.open("https://buy.stripe.com/test_7sI4gqf4efsH4uc3ce");
  // }

  const fetchData = async () => {
    try {
      const response = await fetchPlan();
      const res_data = response.body.data;
      const start_index = 1;
      const result = res_data.slice(start_index);
      setData(result);
      // console.log(result);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const extractFeatures = (features) => {
    return Object.keys(features).map(
      (key) => `${features[key].display_name} ~ ${features[key].count_feature}`
    );
  };

  return (
    <div>
      <div className="flex items-center justify-center h-auto mb-5 -mt-4">
        {data?.map((plan, index) => {
          const cardProps = {
            key: index,
            recommended: plan.PricingPlan.active,
            planName: plan.PricingPlan.display_plan_name,
            price: `$${plan.PricingPlan.plan_price} ${plan.currency}`,
            limit: "Per month, billed annually",
            tagline: "",
            list: extractFeatures(plan.features),
            primaryBtn: "Subscribe",
            primaryBtnFun: validate,
          };
          
          if (plan.PricingPlan.display_plan_name === "Basic") {
            
            cardProps.tagline = "Includes 7 day free trial, plus:";
            
          }
          if (plan.PricingPlan.display_plan_name === "Professional") {
            
            cardProps.tagline = "All the benifits of Basic, plus:";
            
          }
          if (plan.PricingPlan.display_plan_name === "Enterprise") {
            cardProps.price = "Tailored";
            cardProps.limit = "Contact sales for estimates";
            cardProps.tagline = "All the benifits of Professional, plus:";
            cardProps.secondaryBtn = "Get a quote";
            cardProps.secondaryBtnFun = goToSales;
            cardProps.extrabtn = true;
          }

          return (
            <div key={index} className="flex flex-col items-center">
              <CardLayout {...cardProps} />
            </div>
          );
        })}
      </div>
      {/* <div className="flex items-center justify-center mb-5 font-pop500">
        <p><a href="https://www.monere.ai/" className="text-primeBlue">Click here</a> for free trial.</p>
      </div> */}
    </div>
  );
}

export default PricingCard;
